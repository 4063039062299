import React, { useEffect, useState, useCallback }  from "react";
import {columnsContact} from "./data/columns";
import {AdaActions, AdaFilterSubHeader, AdaTable} from "@adaming/ada-react-component";
import GenericDeleteModal from "../../commun/genericDeleteModal";
import GenericSuccessModal from "../../commun/genericSuccesModal";
import IntlMessages from "../../../helpers/intlMessages";
import {withRouter} from "react-router-dom";
import {CRM_NAME, getHostByAppName} from "../../../service/host";
import * as service from "../../../service/crud.service";
import SweetAlert from "react-bootstrap-sweetalert";

const context = "contacts";
const Clients = props => {
    const [contacts, setContacts] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    /*user detail */
    const [hideClientDetailPanel, setHideClientDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);


    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.delete`}/>,
                href: "",
                actionHandler: handlerOpenModalDelete,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.update`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.enable`}/>,
                href: "",
                actionHandler: handlerActivateAction,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.disable`}/>,
                href: "",
                actionHandler: handlerDisableAction,
                disabled: true
            }
        ];
    };

    /*Create operation List*/
    useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideClientDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                actionsValue[0] = {...actionsValue[0], disabled: false};

                /*Activate : Activate User Action*/
                actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Disable User Action*/
                actionsValue[3] = {...actionsValue[3], disabled: false};

                if (selectedRows.length === 1) {
                    actionsValue[1] = {
                        ...actionsValue[1],
                        href: `/${context}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show user detail true*/
                    setHideClientDetailPanel(false);
                }
                setActionsList(actionsValue);
                console.log("actions list : ", actionsValue);
            }
        },
        [selectedRows.length]
    );

    React.useEffect(
        () => {
            if (!modalSuccessShow) {
                service.get(getHostByAppName(CRM_NAME), context, callbackRes, callbackError)
            }
        },
        [modalSuccessShow]
    );

    const callbackRes = data =>{
        setContacts(data);
        setFiltered(data);
        console.log("liste des contacts : ", data);
    }

    const callbackError = error =>{
        console.log("liste des clients : error", error);
    }

    const handlerOpenModalDelete = () => {
        setModalShow(true);
    };


    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handlerDeleteAction = () => {
        console.log("in Delete action", selectedRows);
        service.deletes(getHostByAppName(CRM_NAME), context, callBackResDelete, ((error)=>{console.log(error)}), selectedRows.map(obj => obj.id))
    };

    const callBackResDelete = () => {
        setModalShow(false);
        setModalSuccessShow(true);
    }


    const handlerActivateAction = () => {
        console.log("in Activate action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisableClient(selectedRows.map(element => element.id), true);
    };

    const handlerDisableAction = () => {
        console.log("in Disable action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisableClient(selectedRows.map(element => element.id), false);
    };

    const handlerEnableDisableClient = (ids, status) => {
        console.log("in handlerEnableDisableClient action", ids, status);
        service.putSpe(getHostByAppName(CRM_NAME),`contacts/enable_disable/`,
            (data)=>console.log(data),
            (error)=>console.log(error),
            {
                status: status,
                ids: ids
            },finallyExec)

    };

    const finallyExec = ()=>{
        setLoadingModal(false);
        setModalSuccessShow(true);
    }



    const actionsComponent = (
        <AdaActions context={context} actionsObj={actionsList} showActions={{exportExcel:false, add:true}}/>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsContact}
            items={contacts}
            setFiltredItems={setFiltered}
        />
    );

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsContact}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                            />

                            <SweetAlert
                                onClick={props.processAction}
                                warning
                                showCancel
                                show={modalShow}
                                confirmBtnText="Oui"
                                confirmBtnBsStyle="no-border c-AdaNext c-btn-wide"
                                cancelBtnBsStyle="no-border c-outline-AdaCancel c-btn-wide"
                                title="Confirmation"
                                onConfirm={() => handlerDeleteAction()}
                                onCancel={() => setModalShow(false)}
                            >
                                <IntlMessages id="action.common.confirmation"/>
                            </SweetAlert>

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Succès!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="no-border c-AdaNext c-btn-wide "
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.success"/>
                            </SweetAlert>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(Clients);
