import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import {addressPostal, infoFisc} from "../../../../helpers/constants";

export default function ClientAdministrationInformation(props) {
    const {register, handleSubmit, errors} = useForm();
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = data => {
        props.updateAction({administrationInformation: data});
        props.step("4");
    };

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={infoFisc}
                                         style={{width:"120px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Coordonnées fiscales</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>

                                            <FormGroup>
                                                <IntlMessages id="crm.client.siret"/>
                                                <AdaInputText
                                                    id="siretId"
                                                    name="siret"
                                                    defaultValue={
                                                        props.clientObj.administrationInformation !==
                                                        undefined
                                                            ? props.clientObj.administrationInformation.siret
                                                            : ""
                                                    }
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="crm.client.vatnumberintracommunity"/>
                                                <AdaInputText
                                                    name="vatNumberIntracommunity"
                                                    defaultValue={
                                                        props.clientObj.administrationInformation !==
                                                        undefined
                                                            ? props.clientObj.administrationInformation
                                                                .vatNumberIntracommunity
                                                            : ""
                                                    }
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="crm.client.registration.number"/>
                                                <AdaInputText
                                                    id="registrationNumberId"
                                                    name="registrationNumber"
                                                    defaultValue={
                                                        props.clientObj.administrationInformation !==
                                                        undefined
                                                            ? props.clientObj.administrationInformation
                                                                .registrationNumber
                                                            : ""
                                                    }
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="crm.client.rcs"/>
                                                <AdaInputText
                                                    id="rcsId"
                                                    name="rcs"
                                                    defaultValue={
                                                        props.clientObj.administrationInformation !==
                                                        undefined
                                                            ? props.clientObj.administrationInformation.rcs
                                                            : ""
                                                    }
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <AdaButton
                                                    type="submit"
                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                >
                                                    <IntlMessages id="action.common.next.entities"/>
                                                </AdaButton>
                                                <AdaButton
                                                    onClick={() => props.step("2")}
                                                    className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                >
                                                    <IntlMessages id="action.common.previous.mailing.address"/>
                                                </AdaButton>
                                                <AdaButton
                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    onClick={props.cancelAction}
                                                >
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
