import Clients from "../views/crm/client/clients"
import ClientForm from "../views/crm/client/newClient/newClient"
import AdaClientUpdate from "../views/crm/client/adaClientUpdate/AdaClientUpdate";
import Maintenance from "../views/pages/error/maintenance";

const clientsRoutes = [
    {path: "/clients", component: Clients},
    {path: "/clients/new", component: ClientForm},
    {path: "/clients/:clientId/edit", component: AdaClientUpdate},
    { path: "/maintenance", component: Maintenance}
];

export default clientsRoutes;