import React, { useEffect, useState } from "react";
import ClientInformation from "./clientInformation";
import ClientContacts from "./clientContacts";
import ClientAdministrationInformation from "./clientInformationFiscale";
import ClientMailingAddress from "./clientAdressePostal";
import ClientBankDetails from "./clientCordBancaires";
import {Card, CardText, CardTitle, Col, Nav, NavLink, NavItem, Row, TabContent, TabPane, Label} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../../helpers/intlMessages";
import { useHistory } from "react-router-dom";
import ClientEntities from "./clientEntities";
import {CRM_NAME, getHostByAppName} from "../../../../service/host";
import * as service from "../../../../service/crud.service";


export default function ClientForm(props) {
    const [activeTab, setActiveTab] = useState("1");
    const [client, setClient] = useState({});
    const [loadingModal, setLoadingModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [bankDetails, setBankDetails] = useState([]);
    const [entities, setEntities] = useState([]);
    const [selectedObjects, setSelectedObjects] = useState({})
    const [listContacts, setListContacts] = React.useState([]);
    const [listContactsByEntitie, setListContactsByEntitie] = React.useState([]);

    let history = useHistory();

    const step = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const cancel = () => {
        history.push("/clients");
    };

    const setClientInformation = data => {
        console.log("clien ",client)
        setClient({ ...client, ...data });
    };

    const saveClient = object => {
        setLoadingModal(true);
        setErrorMessage("");

        service.post(
            getHostByAppName(CRM_NAME),
            `clients`,
            callBackRes,
            callBackError,
            { ...client, ...object, ...selectedObjects }
        );
    };

    const callBackRes = data => {
        history.push("/clients");
    }

    const callBackError = error => {
        setErrorMessage(error.response.data.message);
        setLoadingModal(false);
    }

    const initBankDetailsList = affiliationObj => {
        if (affiliationObj !== undefined) {
            if (affiliationObj.bankDetails !== undefined) {
                setBankDetails([
                    {
                        key: affiliationObj.bankDetails,
                        label:
                        affiliationObj.bankDetails.designation,
                        value:
                        affiliationObj.bankDetails.designation,
                        object: affiliationObj.bankDetails
                    }
                ]);
            }
        } else {
            setBankDetails([]);
        }
    };

    const initEntityList = entityList => {
        setEntities(entityList);
    };

    const dispatch = (obj) => {
        console.log("selectItems values : ",selectedObjects)
        setSelectedObjects({...selectedObjects, ...obj});
    }

    useEffect(() => {}, [activeTab]);

    return (
        <div>
            <h4><IntlMessages id={`crm.title.create.client`} /></h4>
            <br />
            <Label>
                <p style={{ color: "red" }}> {errorMessage}</p>
            </Label>
            <Nav tabs>
                <NavItem className="roe-card-body">
                    <NavLink className={classnames({ active: activeTab === "1" })}>
                        <IntlMessages id={`crm.common.information`} />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === "2" })}>
                        <IntlMessages id={`crm.client.contact.mailing.address`} />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === "3" })}>
                        <IntlMessages id={`crm.common.information.fiscal`} />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === "4" })}>
                        <IntlMessages id={`crm.common.entities`} />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === "5" })}>
                        <IntlMessages id={`crm.common.contacts`} />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === "6" })}>
                        <IntlMessages id={`crm.common.bank.details`} />
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    {
                        activeTab === "1" && <Row>
                            <Col sm="12">
                                <Card body>
                                    <CardTitle/>
                                    <CardText>
                                        <ClientInformation
                                            step={step}
                                            cancelAction={cancel}
                                            clientObj={client}
                                            updateAction={setClientInformation}
                                            initBankDetails={initBankDetailsList}
                                            dispatch={dispatch}
                                            selectedObjects={selectedObjects}
                                        />
                                    </CardText>
                                </Card>
                            </Col>
                        </Row>
                    }
                </TabPane>
                <TabPane tabId="2">
                    {
                        activeTab === "2" && <Col sm="15">
                            <Card body>
                                <CardText>
                                    <ClientMailingAddress
                                        step={step}
                                        cancelAction={cancel}
                                        clientObj={client}
                                        updateAction={setClientInformation}
                                        dispatch={dispatch}
                                        selectedObjects={selectedObjects}
                                    />
                                </CardText>
                            </Card>
                        </Col>
                    }
                </TabPane>
                <TabPane tabId="3">
                    {
                        activeTab === "3" && <Col sm="15">
                            <Card body>
                                <CardText>
                                    <ClientAdministrationInformation
                                        step={step}
                                        cancelAction={cancel}
                                        clientObj={client}
                                        updateAction={setClientInformation}
                                    />
                                </CardText>
                            </Card>
                        </Col>
                    }
                </TabPane>
                <TabPane tabId="4">
                    {
                        activeTab === "4" && <Col sm="15">
                            <Card body>
                                <CardText>
                                    <ClientEntities
                                        step={step}
                                        cancelAction={cancel}
                                        clientObj={client}
                                        initEntityList={initEntityList}
                                        updateAction={setClientInformation}
                                    />
                                </CardText>
                            </Card>
                        </Col>
                    }
                </TabPane>
                <TabPane tabId="5">
                    {
                        activeTab === "5" && <Col sm="15">
                            <Card body>
                                <CardText>
                                    <ClientContacts
                                        listContacts={listContacts}
                                        setListContacts={setListContacts}
                                        listContactsByEntitie={listContactsByEntitie}
                                        setListContactsByEntitie={setListContactsByEntitie}
                                        step={step}
                                        cancelAction={cancel}
                                        clientObj={client}
                                        updateAction={setClientInformation}
                                        listEntities={entities}
                                        dispatch={dispatch}
                                        selectedObjects={selectedObjects}
                                    />

                                </CardText>
                            </Card>
                        </Col>
                    }
                </TabPane>
                <TabPane tabId="6">
                    {
                        activeTab === "6" && <Col sm="15">
                            <Card body>
                                <CardText>
                                    <ClientBankDetails
                                        step={step}
                                        cancelAction={cancel}
                                        clientObj={client}
                                        updateAction={setClientInformation}
                                        saveClient={saveClient}
                                        bankDetailsList={bankDetails}
                                    />
                                </CardText>
                            </Card>
                        </Col>
                    }
                </TabPane>
            </TabContent>
        </div>
    );
}
