import React, {Fragment, useEffect, useState} from "react";
import "../adaClientUpdate/AdaClientUpdate.scss";
import Accordion from "./Accordion";
import {Col, Collapse, CustomInput, FormGroup, Row, Table} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import GenericSuccessModal from "../../../commun/genericSuccesModal";
import {useHistory} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {addressPostal, buildingEnterprise, contacticon, fatura, infoban, infoFisc} from "../../../../helpers/constants";
import WrapperInformation from "./wrapper";
import LatestActivity from "./UserInfoDoughnutWidget";
import * as service from "../../../../service/crud.service";
import {CRM_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../../../service/host";
import UpdateInformation from "./updateInformation";
import ContactsDetails from "../clientDetails/contactsDetails";
import DisplayInformationStyle from "./displayInformationStyle";
import UpdateFiscalStyle from "./updateFiscalInformation";
import UpdateBankInformation from "./updateBankInformation";
import UpdateAddressInformation from "./updateAdresse";
import {string} from "prop-types";


const AdaClientUpdate = (props) => {
    const [client, setClient] = useState({});
    const [modal, setModal] = useState(false);
    const clientId = initclientId();
    const [status, setStatus] = useState("moins");
    const [collapse, setCollapse] = useState(false);
    const [collapse1, setCollapse1] = useState(false);
    /*Edit mode */
    const [editInformation, setEditInformation] = useState(false);
    const [editIF, setEditIF] = useState(false);
    const [editadrs, setEditadrs] = useState(false);
    const [editBK, setEditBK] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);

    /*Register form */
    const {register, handleSubmit, errors, setValue, control} = useForm();
    const [activityAreaList, setActivityAreaList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [values, setReactSelect] = useState({
        selectedOption: []
    });

    const [activityArea, setActivityArea] = useState({});
    const [address, setAddress] = useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [contacts, setContacts] = useState([]);
    const [selectedObject, setSelectedObject] = useState({})


    const contactId = props.match.params.contactId;

    /*React.useEffect(() => {
        if (contactId !== undefined) {
            const fetchData = async () => {
                setIsLoading(true);
                const result = await axios.get(`http://localhost:8081/contacts/${contactId}`);
                setContacts(result.data);
                setIsLoading(false);
            };
            fetchData();
        }
    }, [contactId]);*/

    const dispatchSelectObj = data =>{
        setSelectedObject({...selectedObject, ...data})
    }

    useEffect(() => {
        if(client.id){
            service.get(
                getHostByAppName(CRM_NAME),
                `clients/contacts/${client.id}`,
                callbackContactsList,
                (error)=> console.log("error",error)
            );
        }
    }, [client]);

    const callbackContactsList = (data) =>{
        console.log("Map contacts", data);
        setContacts(data);
    }

    const editContact = (contactId) => {
        history.push(`/contacts/${contactId}/edit`);
    };

    let history = useHistory();

    function initclientId() {
        const {
            match: {params}
        } = props;
        return params.clientId;
    }

    /*const handlerSaveNoteAction = data => {
        let tab = [];
        tab.push({subject: data.subject, text: data.text});
        if (client.notes !== undefined && client.notes.length > 0) {
            client.notes.forEach(note => tab.push(note));
        }
        axios
            .patch(`${getHostByAppName(CRM_NAME)}/clients/${client.id}`, {
                notes: tab
            })
            .then(res => {
                setClient(res.data);
                setModalShow(false);
                setModalSuccessShow(true);
            });
    };*/



    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            service.getById(
                getHostByAppName(CRM_NAME),
                `clients`,
                setClient,
                error => console.log(error), clientId
            );
            setIsLoading(false);
        };
        fetchData();


    }, []);

    const getAddressByClient = client => {
        if (client.id !== undefined) {
            service.get(
                getHostByAppName(CRM_NAME),
                `clients/${client.id}/address`,
                callbackResult,
                (error)=> console.log("error",error)
            );

        }
    }

    useEffect(() => {
        getAddressByClient(client)
    }, [client]);

    const callbackResult = (data) =>{
        console.log("dddddd",data)
        data && data.length> 0 && data[0].id ? setAddress(data[0])
            : setAddress({
                city: "",
                country: "",
                district: "",
                streetName: "",
                streetNumber: "",
                zipCode: "",
                })
    }

    const saveEdit = data => {
        console.log("in saveClient update",{id:clientId,...data})
        service.patch(getHostByAppName(CRM_NAME),`clients`,callBackSave,(error)=>setErrorMessage(error.toString()),{id:clientId,...data})
    }

    const callBackSave = res => {
        setClient(res.data);
        setEditInformation(false);
    }

    const onSubmitInformation = data => {
        setEditInformation(false);
        saveEdit(
            {...data, ...selectedObject}
        )
    };


    const onSubmitInformationFiscal = data => {
        setEditIF(false);
        saveEdit(
            data
        )
    };

    const onSubmitAdresse = data => {
        console.log("address",data)
        console.log("address json",JSON.stringify(data))
        service.put(getHostByAppName(CRM_NAME),`clients/${clientId}/address`,callbackResAddress,(error)=>setErrorMessage(error.toString()),
            {id:address.id ? address.id : undefined, relatedTo : address.relatedTo ? address.relatedTo : client.id,...data})

    };

    const callbackResAddress = data =>{
        getAddressByClient(client)
        setEditadrs(false);
    }

    const onSubmitBankDetails = data => {
        setEditBK(false);
        saveEdit(
            data
        )
    };



    return (
        <Fragment>
            {isLoading ? (
                <div><IntlMessages id="crm.common.loading.message"/></div>
            ) : (
                <div>
                    <div className="row ma-0">
                        <div className="col-lg-12 ptb-10">

                            <div className="roe-card-style">
                                <div className="roe-card-header">
                                </div>
                                <div className="roe-card-body">
                                    <div>
                                        <form onSubmit={handleSubmit(onSubmitInformation)}>

                                            <Row>
                                                <Col>
                                                    {!editInformation && (

                                                    <LatestActivity
                                                        icon={buildingEnterprise}
                                                        title={client.name}
                                                        description={client.description ? client.description : "ND"}
                                                        site={client.web ? client.web : "ND"}
                                                        telephone={client.contactInformation && client.contactInformation.phone ? client.contactInformation.phone : "ND"}
                                                        email={client.contactInformation && client.contactInformation.email ? client.contactInformation.email : "ND"}
                                                        fax={client.contactInformation && client.contactInformation.fax ? client.contactInformation.fax : "ND"}
                                                        statut={client.enable === true ? "Activé" : "Désactivé"}
                                                        owner={client.owner}
                                                        activityArea={client.activityArea}
                                                        termsOfPayment={client.termsOfPayment}
                                                        group={client.group ? client.group : "ND" }
                                                    />)}
                                                </Col>
                                            </Row>

                                            {editInformation && client.id && <UpdateInformation icon={buildingEnterprise}
                                                                                                title={client.name} control={control} register={register} errors={errors} setValue={setValue} client={client} dispatch={dispatchSelectObj}/>}

                                            <br/>
                                            <div>
                                                <Row>
                                                    <Col className="col-sm-2 ml-auto">
                                                        {!editInformation && (
                                                            <AdaButton
                                                                className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                onClick={() => {
                                                                    setEditInformation(true);
                                                                    setCollapse(false);

                                                                }}
                                                            >
                                                                <IntlMessages id="action.common.update"/>
                                                            </AdaButton>

                                                        )}

                                                        {editInformation && (
                                                            <FormGroup>
                                                                <AdaButton
                                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                    type="submit"
                                                                >
                                                                    <IntlMessages id="action.common.submit"/>
                                                                </AdaButton>
                                                                <AdaButton
                                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                    onClick={() => setEditInformation(false)}
                                                                >
                                                                    <IntlMessages id="action.common.cancel"/>
                                                                </AdaButton>

                                                            </FormGroup>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*Accordion section*/}


                    <Accordion title={<IntlMessages id="crm.common.contacts"/>}>
                        <div className="col-lg-12 ptb-15">
                            <ContactsDetails client={client} edit={true} editContact={editContact}/>
                        </div>
                    </Accordion>

                    {/*<Accordion title={<IntlMessages id="crm.common.notes"/>}>
                        <div className="row ma-0">
                            <br/>
                            <div className="col-lg-12 ptb-10">
                                <div className="roe-card-style">
                                    <div className="roe-card-body">

                                        <NotesDetails client={client} onClick={() => setModalShow(true)}/>
                                        <GenericAddNote
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            processAction={handlerSaveNoteAction}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion>*/}
                    <SweetAlert
                        success
                        show={modalSuccessShow}
                        title="Succès!"
                        onConfirm={() => setModalSuccessShow(false)}
                        confirmBtnCssClass="sweet-alert-confirm-button"
                        cancelBtnCssClass="sweet-alert-cancle-button"
                    >
                        <IntlMessages id="action.common.succes"/>
                    </SweetAlert>


                    <Accordion edit={editIF} title={<IntlMessages id="crm.common.information.fiscal"/>}>
                        <div className="row ma-0">
                            <br/>
                            <div className="col-lg-12 ptb-10">
                                <div className="roe-card-style">
                                    <div className="roe-card-header">
                                    </div>
                                    <div className="roe-card-body">

                                        {!editIF && <DisplayInformationStyle  icon={infoFisc}
                                                                  title={"Coordonnées fiscales"}
                                                                  inf1={{title : "Code RCS", value : client.administrationInformation && client.administrationInformation.rcs ? client.administrationInformation.rcs : "ND"}}
                                                                  inf4={{title : "Siret", value : client.administrationInformation && client.administrationInformation.siret ? client.administrationInformation.siret : "ND"}}
                                                                  inf2={{title : "Numéro d'enregistrement", value : client.administrationInformation && client.administrationInformation.registrationNumber ? client.administrationInformation.registrationNumber : "ND"}}
                                                                  inf5={{title : "Tva intracommunautaire", value : client.administrationInformation && client.administrationInformation.vatNumberIntracommunity ? client.administrationInformation.vatNumberIntracommunity : "ND"}}
                                        />}



                                            <form onSubmit={handleSubmit(onSubmitInformationFiscal)}>
                                                {editIF && <UpdateFiscalStyle client={client} errors={errors} register={register} icon={infoFisc}
                                                                   title={"Coordonnées fiscales"}/>}
                                                <br/>

                                                <div>
                                                    <Row>
                                                        <Col className="col-sm-2 ml-auto">
                                                            {!editIF && (
                                                                <AdaButton
                                                                    className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                    onClick={() => setEditIF(true)}
                                                                >
                                                                    <IntlMessages id="action.common.update"/>
                                                                </AdaButton>
                                                            )}

                                                            {editIF && (
                                                                <FormGroup>

                                                                    <AdaButton
                                                                        className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                                        type="submit"
                                                                    >
                                                                        <IntlMessages id="action.common.submit"/>
                                                                    </AdaButton>

                                                                    <AdaButton
                                                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                        onClick={() => setEditIF(false)}
                                                                    >
                                                                        <IntlMessages id="action.common.cancel"/>
                                                                    </AdaButton>
                                                                </FormGroup>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </form><div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Accordion>

                    <Accordion edit={editadrs} title={<IntlMessages id="crm.common.mailing.address"/>}>
                        <div className="row ma-0">
                            <br/>
                            <div className="col-lg-12 ptb-10">
                                <div className="roe-card-style">
                                    <div className="roe-card-body">
                                        <div>
                                            <form onSubmit={handleSubmit(onSubmitAdresse)}>
                                                <br/>


                                                            {!editadrs && address !== undefined && (

                                                                <WrapperInformation title={<IntlMessages
                                                                    id="crm.common.mailing.address"/>}
                                                                                    icon={addressPostal}
                                                                                    informationLigne1={address.id ? (address.streetNumber + " " + address.streetName):"ND"}
                                                                                    informationLigne2={address.zipCode + " " + address.city + " " +address.country}/>
                                                            )}

                                                {editadrs && <UpdateAddressInformation address={address} errors={errors} register={register} title={"Adresse postale"} icon={addressPostal}/>}
                                                <br/>
                                                <div>
                                                    <Row>
                                                        <Col className="col-sm-2 ml-auto">
                                                            {!editadrs && (
                                                                <AdaButton
                                                                    className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                    onClick={() => setEditadrs(true)}
                                                                >
                                                                    <IntlMessages id="action.common.update"/>
                                                                </AdaButton>
                                                            )}

                                                            {editadrs && (
                                                                <FormGroup>
                                                                    <AdaButton
                                                                        className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                                        type="submit"
                                                                    >
                                                                        <IntlMessages id="action.common.submit"/>
                                                                    </AdaButton>

                                                                    <AdaButton
                                                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                        onClick={() => setEditadrs(false)}
                                                                    >
                                                                        <IntlMessages id="action.common.cancel"/>
                                                                    </AdaButton>
                                                                </FormGroup>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Accordion>


                    <Accordion edit={editBK} title={<IntlMessages id="crm.common.bank.details"/>}>

                        <div className="row ma-0">
                            <br/>
                            <div className="col-lg-12 ptb-10">
                                <div className="roe-card-style">

                                    <div className="roe-card-body">




                                        <div>
                                            <form onSubmit={handleSubmit(onSubmitBankDetails)}>
                                                <br/>
                                                {!editBK && <DisplayInformationStyle  icon={infoban}
                                                                                      title={"Coordonnées bancaires"}
                                                                                      inf1={{title : "Titulaire", value : client.bankDetails && client.bankDetails.owner ? client.bankDetails.owner : "ND"}}
                                                                                      inf4={{title : "Banque", value : client.bankDetails && client.bankDetails.designation ? client.bankDetails.designation : "ND"}}
                                                                                      inf2={{title : "RIB", value : client.bankDetails && client.bankDetails.rib ? client.bankDetails.rib : "ND"}}
                                                                                      inf5={{title : "BIC", value : client.bankDetails && client.bankDetails.bic ? client.bankDetails.bic : "ND"}}
                                                                                      inf3={{title : "IBAN", value : client.bankDetails && client.bankDetails.iban ? client.bankDetails.iban : "ND"}}
                                                />}

                                                {editBK && <UpdateBankInformation icon={infoban} title={"Coordonnées bancaires"} register={register} errors={errors} client={client}/>}

                                                <br/>

                                                <div>
                                                    <Row>
                                                        <Col className="col-sm-2 ml-auto">
                                                            {!editBK && (
                                                                <AdaButton
                                                                    className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                    onClick={() => setEditBK(true)}
                                                                >
                                                                    <IntlMessages id="action.common.update"/>
                                                                </AdaButton>
                                                            )}

                                                            {editBK && (
                                                                <FormGroup>
                                                                    <AdaButton
                                                                        className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                                        type="submit"
                                                                    >
                                                                        <IntlMessages id="action.common.submit"/>
                                                                    </AdaButton>

                                                                    <AdaButton
                                                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                        onClick={() => setEditBK(false)}
                                                                    >
                                                                        <IntlMessages id="action.common.cancel"/>
                                                                    </AdaButton>

                                                                </FormGroup>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Accordion>




                    <GenericSuccessModal show={modal} onHide={() => setModal(false)}/>
                </div>)}
        </Fragment>
    );
};

export default AdaClientUpdate;
