import React, {Fragment, useEffect, useState} from "react";
import {CustomInput, FormGroup, Input, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {Controller, useForm} from "react-hook-form";
import AdaSelectBuilder from "../../commun/ada.select.builder";
import AdaAddressBuilder from "../../commun/ada.address.builder";
import {CRM_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../../service/host";
import * as service from "../../../service/crud.service";

export default function ContactForm(props) {

    const {register, handleSubmit, errors, setValue, control} = useForm();

    const [errorMessage, setErrorMessage] = useState("");
    const [contact, setContact] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedObjects, setSelectedObjects] = useState({})
    const [clientList, setClientList] = useState([]);
    const [entityList, setEntityList] = useState([]);

    const contactId = props.match.params.contactId;
    let history = useHistory();

    useEffect(() => {
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)

        if (contactId !== undefined) {
            const fetchData = async () => {
                setIsLoading(true);
                service.get(getHostByAppName(CRM_NAME),`contacts/${contactId}`,setContact,(error)=>console.log("print error",error))
                //const result = await axios.get(`${getHostByAppName(CRM_NAME)}/contacts/${contactId}`);
            };
            fetchData();
        }
    }, [contactId]);


    useEffect(()=>{
        if(contact.client){
            initClientObj(contact.client)
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${contact.client}`, setEntityList)
        }
        if(contact.entity){
            initEntityObj(contact.entity)
        }
        initSelectedObject(contact)
        setIsLoading(false);
    },[contact])

    const initClientObj = (id) =>{
        service.getById(getHostByAppName(CRM_NAME), "clients", callbackGetIdClient, (error)=> console.log(error), id)
    }

    const initEntityObj = (id) =>{
        service.getById(getHostByAppName(CRM_NAME), "entitys", callbackGetIdEntity, (error)=> console.log(error), id)
    }

    const callbackGetIdClient = (data) =>{
        setValue(`client`, {key: data.id,label: data.name,value: data.id, object: data});
    }
    const callbackGetIdEntity = (data) =>{
        setValue(`entity`, {key: data.id,label: data.name,value: data.id, object: data});
    }

    const initSelectedObject = (contactObj) =>{
        let obj = {
            civility: contactObj.civility ? contactObj.civility : null,
            companyFunction : contactObj.companyFunction ? contactObj.companyFunction : null,
            companyCategory : contactObj.companyCategory  ? contactObj.companyCategory : null,
            client:contactObj.client ? contactObj.client : null ,
            entity:contactObj.entity ? contactObj.entity : null
        }
        dispatch(obj);
    }

    const handleChangeClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log(selectedOption);
        if (selectedOption !== null) {
            setValue(`client`, selectedOption.object);
            dispatch({client:selectedOption.value, entity:null})
            setValue(`entity`, null);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.object.id}`, setEntityList)
        } else {
            setEntityList([]);
            dispatch({client:null, entity:null})
            setValue(`client`, null);
            setValue(`entity`, null);

        }
    };

    const handleChangeEntity = selectedOptionTab => {
        console.log("entity", selectedOptionTab);
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            dispatch({entity:selectedOption.value})
            setValue(`entity`, selectedOption.object);
        } else {
            dispatch({entity:null})
            setValue(`entity`, null);
        }
    };

    useEffect(() => {
        register({name: "id"});
        register({name: "enable"});
        register({name: "code"});
        register({name: "label"});
        register({name: "organization"});
        register({name: "civility"});
        register({name: "firstName"});
        register({name: "lastName"});
        register({name: "type"});
        register({name: "relatedTo"});
        register({name: "owner"});

        register({name: "responsible"});

        register({name: "companyFunction"});
        register({name: "companyCategory"});

    }, []);

    const dispatch = (obj) => {
        setSelectedObjects({...selectedObjects, ...obj});
    }


    const onSubmit = data => {
        console.log("data",data,selectedObjects)
        let contact = {
            id : contactId,
            firstName: data.firstName,
            lastName: data.lastName,
            enable: data.enable,
            responsible: data.responsible,
            contactInformation: data.contactInformation,
            address: data.address,
            owner: "user",
            relatedTo: selectedObjects.entity ? selectedObjects.entity : (selectedObjects.client ? selectedObjects.client : undefined),
            ...selectedObjects,
        }

        if (contactId !== undefined && contactId !== null) {
            service.put(getHostByAppName(CRM_NAME),`contacts`,()=>history.push("/contacts"),(error)=>setErrorMessage(error.toString()),contact)
        } else {
            service.post(getHostByAppName(CRM_NAME),`contacts`,()=>history.push("/contacts"),(error)=>setErrorMessage(error.toString()),contact)
        }
    };

    return (
        <Fragment>
            {isLoading ? (
                <div><IntlMessages id="crm.common.loading.message"/></div>
            ) : (<div>
                <div>
                    <div className="row ma-0">
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col>
                                                <Label>
                                                    <p style={{color: "red"}}> {errorMessage}</p>
                                                </Label>

                                                <FormGroup>
                                                    <div>
                                                        <Row>
                                                            <Col

                                                                style={{"margin-top": "20px"}}
                                                            >
                                                                <FormGroup>
                                                                    <CustomInput
                                                                        type="switch"
                                                                        id="actifContactClt1"
                                                                        name="enable"
                                                                        defaultChecked={contact.enable !== undefined ? contact.enable : true}
                                                                        innerRef={register}
                                                                        label={<IntlMessages id="crm.common.status"/>}
                                                                    />

                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </FormGroup>

                                                <FormGroup>
                                                    <div>
                                                        <Row>
                                                            <Col
                                                                className="col-sm-2"
                                                                style={{"margin-top": "10px"}}
                                                            >
                                                                <AdaSelectBuilder errors={errors} register={register}
                                                                                  setValue={setValue}
                                                                                  uri={`${getHostByAppName(NOMENCLATURE_NAME)}/civilitys`}
                                                                                  inputName={"civility"}
                                                                                  optionsType={"string"}
                                                                                  defaultId={contact.civility}
                                                                                  control={control}
                                                                                  dispatch={dispatch}/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </FormGroup>


                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.client.contact.lastname"/>
                                                            <AdaInputText
                                                                id={"firstNameId"}
                                                                name="firstName"
                                                                defaultValue={contact.firstName}
                                                                errors={errors}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.client.contact.firstname"/>
                                                            <AdaInputText
                                                                id={"lastNameId"}
                                                                name="lastName"
                                                                defaultValue={contact.lastName}
                                                                errors={errors}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.common.contacts.phone"/>
                                                            <AdaInputText
                                                                id={"contactInformationPhoneId"}
                                                                name="contactInformation.phone"
                                                                defaultValue={contact.contactInformation !== undefined ? contact.contactInformation.phone : ""}
                                                                errors={errors}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.common.contacts.mobile"/>
                                                            <AdaInputText
                                                                id={"contactInformationMobileId"}
                                                                name="contactInformation.mobile"
                                                                errors={errors}
                                                                defaultValue={contact.contactInformation !== undefined ? contact.contactInformation.mobile : ""}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.common.contacts.email"/>
                                                            <Input
                                                                id={"contactInformationEmailId"}
                                                                name="contactInformation.email"
                                                                type="email"
                                                                defaultValue={contact.contactInformation !== undefined ? contact.contactInformation.email : ""}
                                                                errors={errors}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.common.contacts.fax"/>
                                                            <AdaInputText
                                                                id={"contactInformationFaxId"}
                                                                name="contactInformation.fax"
                                                                defaultValue={contact.contactInformation !== undefined ? contact.contactInformation.fax : ""}
                                                                errors={errors}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.client.contact.function"/>

                                                            <AdaSelectBuilder errors={errors} register={register}
                                                                              setValue={setValue}
                                                                              uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_functions`}
                                                                              inputName={"companyFunction"}
                                                                              optionsType={"string"}
                                                                              defaultId={contact.companyFunction}
                                                                              control={control}
                                                                              dispatch={dispatch}/>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.client.contact.category"/>
                                                            <AdaSelectBuilder errors={errors} register={register}
                                                                              setValue={setValue}
                                                                              uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_categorys`}
                                                                              inputName={"companyCategory"}
                                                                              optionsType={"string"}
                                                                              defaultId={contact.companyCategory}
                                                                              control={control}
                                                                              dispatch={dispatch}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>


                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.client"/>
                                                            <Controller
                                                                as={<AdaSelect name={"client"}/>}
                                                                name={"client"}
                                                                isClearable
                                                                errors={errors}
                                                                options={clientList}
                                                                onChange={handleChangeClient}
                                                                control={control}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="crm.entity"/>
                                                            <Controller
                                                                as={<AdaSelect name={"entity"}/>}
                                                                name={"entity"}
                                                                isClearable
                                                                errors={errors}
                                                                options={entityList}
                                                                onChange={handleChangeEntity}
                                                                control={control}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>



                                                <AdaAddressBuilder errors={errors} register={register} setValue={setValue}
                                                                   uri={`${getHostByAppName(CRM_NAME)}/contacts/${contactId}/address`}
                                                                   relatedTo={contactId} inputName={"address"}/>

                                                <FormGroup>
                                                    <AdaButton type="submit"
                                                               className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right  ml-sm-2">
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                    <AdaButton
                                                        type="sumbit"
                                                        style={{marginLeft: 10}}
                                                        onClick={() => history.push("/contacts")}
                                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    >
                                                        <IntlMessages
                                                            id="action.common.cancel"
                                                        />
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </Fragment>
    );
}
