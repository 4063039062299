import React, {useEffect, useState} from "react";
import {AdaButton, AdaInputText, AdaInputTextArea} from "@adaming/ada-react-component";
import {FormGroup, Label, Row, CustomInput} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import AdaSelectBuilder from "../../../commun/ada.select.builder";
import {CRM_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../../../service/host";
import {buildingEnterprise} from "../../../../helpers/constants";

export default function ClientInformation(props) {
    const {register, handleSubmit, errors, setValue, control} = useForm();
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = data => {
        props.updateAction({
            name: data.name,
            group : data.group,
            web: data.web,
            description: data.description,
            enable: data.enable,
            affiliate: data.affiliate !== undefined ? data.affiliate : null,
            activityArea: data.activityArea !== undefined ? data.activityArea : null,
            owner: "admin",
            termsOfPayment: data.termsOfPayment,
            contactInformation: {
                phone: data.phone
            }
        });
        props.step("2");
    };

    useEffect(() => {
        register({name: "affiliate"});
        register({name: "activityArea"});
        register({name: "name"}, {required: "Ce champ est obligatoire."});
    }, []);

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={buildingEnterprise}
                                         style={{width:"100px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Information générale</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="crm.client.designation"/>
                                                        <AdaInputText
                                                            name="name"
                                                            errors={errors}
                                                            innerRef={register}
                                                            defaultValue={props.clientObj.name}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="invoice.terms.payment"/>
                                                        <AdaInputText
                                                            name="termsOfPayment"
                                                            type="number"
                                                            defaultValue={props.clientObj.termsOfPayment}
                                                            placeholder="0"
                                                            step="1"
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="pt-4">

                                                    <FormGroup>
                                                        <CustomInput
                                                            type="switch"
                                                            id="statusInfClt"
                                                            name="enable"
                                                            defaultChecked={props.clientObj.enable !== undefined ? props.clientObj.enable : true}
                                                            innerRef={register}
                                                            label={<IntlMessages id="crm.client.enable"/>}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="col-sm-4">
                                                    <FormGroup>
                                                        <IntlMessages id="crm.client.affiliate"/>
                                                        <AdaSelectBuilder errors={errors} register={register}
                                                                          setValue={setValue} resource={"clients"}
                                                                          uri={`${getHostByAppName(CRM_NAME)}/clients`}
                                                                          inputName={"affiliate"} optionsType={"object"}
                                                                          defaultId={props.selectedObjects.affiliate}
                                                                          control={control}
                                                                          dispatch={props.dispatch}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="crm.client.group"/>
                                                        <AdaInputText
                                                            name="group"
                                                            defaultValue={props.clientObj.group}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="crm.client.description"/>
                                                        <AdaInputTextArea
                                                            name="description"
                                                            defaultValue={props.clientObj.description}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-sm-9">
                                                    <FormGroup>
                                                        <IntlMessages id="crm.client.web"/>
                                                        <AdaInputText
                                                            name="web"
                                                            defaultValue={props.clientObj.web}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="crm.common.phone.number"/>
                                                        <AdaInputText
                                                            name="phone"
                                                            defaultValue={props.clientObj.contactInformation !== undefined ? props.clientObj.contactInformation.phone : ""}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <FormGroup>
                                                <IntlMessages id="crm.client.sector.activity"/>
                                                <AdaSelectBuilder errors={errors} register={register}
                                                                  setValue={setValue}
                                                                  uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_activity_areas`}
                                                                  inputName={"activityArea"} optionsType={"string"}
                                                                  defaultId={props.selectedObjects.activityArea}
                                                                  control={control}
                                                                  dispatch={props.dispatch}/>
                                            </FormGroup>
                                            <FormGroup>
                                                <AdaButton type="submit"
                                                           className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.common.next.information.fiscal"/>
                                                </AdaButton>
                                                <AdaButton onClick={() => props.step("1")}
                                                           className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                >
                                                    <IntlMessages id="action.common.previous.information"/>
                                                </AdaButton>
                                                <AdaButton
                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    onClick={props.cancelAction}
                                                >
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
