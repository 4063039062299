import React from "react";
import IntlMessages from "../../../../helpers/intlMessages";
import SocialProfileInfoWrapper from "../../../../components/profile/contact/socialProfile.style";

export default function WrapperInformation({title, icon, informationLigne1, informationLigne2,showFooter,onclickDetails}) {
    return (
        <div>
            <SocialProfileInfoWrapper>
                <div className="widget-container">
                    <div className="roe-card-style">
                        <div className="roe-card-body pt-24">
                            <div className="profile-widget-image text-center">
                                <img
                                    src={icon}
                                />
                            </div>
                            <div className="text-center">
                                <div
                                    className="fs-16 bold-text mt-10 widget-dark-color">
                                    {title}
                                </div>
                                <div
                                    className="fs-12 widget-light-grey-color medium-text mt-10">
                                    {informationLigne1}
                                    <br/>
                                    {informationLigne2}
                                </div>
                            </div>
                        </div>
                        {showFooter && <div className="flex-x action-container">

                            <div
                                className="action fs-13 demi-bold-text widget-dark-color">
                                <i className="far fa-user mr-6 fs-12"></i>
                                <div className="fs-12 demi-bold-text cursor-pointer"
                                     onClick={onclickDetails}
                                >
                                    <IntlMessages id="header.editprofile"/>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </SocialProfileInfoWrapper>
        </div>
    );
}
