import ActivityAreaForm from "../views/crm/activityarea/activityAreaForm";
import ActivityArea from "../views/crm/activityarea/activityAreas";

const activityAreasRoutes = [
    {path: "/activity_areas", component: ActivityArea},
    {path: "/activity_areas/new", component: ActivityAreaForm},
    {path: "/activity_areas/:activityAreasId/edit", component: ActivityAreaForm},

];

export default activityAreasRoutes;