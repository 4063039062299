import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {Breadcrumb, CustomInput, FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {infoban, infoFisc} from "../../../../helpers/constants";

export default function ClientCordBancaires(props) {
    const {register, handleSubmit, errors, setValue} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");

    const onSubmit = data => {
        console.log("log bank details : ", data);
        props.saveClient({
            bankDetails: {
                bank: data.name,
                rib: data.rib,
                iban: data.iban,
                bic: data.bic,
                owner: data.owner
            }
        });
    };

    useEffect(() => {
        register({name: "bank"});
        register({name: "owner"});
        register({name: "designation"});
        register({name: "iban"});
        register({name: "rib"});
        register({name: "bic"});
    });

    const handleChangeAffiliationInfBank = selectedOption => {
        console.log("affiliation : information bancaire", selectedOption);
        setValue("owner", selectedOption.object.owner);
        setValue("designation", selectedOption.object.designation);
        setValue("iban", selectedOption.object.iban);
        setValue("rib", selectedOption.object.rib);
        setValue("bic", selectedOption.object.bic);
    };

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={infoban}
                                         style={{width:"120px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Coordonnées Bancaires</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>

                                            {props.clientObj.affiliation !== undefined && props.clientObj.affiliation !== null && (
                                                <FormGroup>
                                                    <IntlMessages id="crm.common.bank.details.affiliate"/>
                                                    <AdaSelect
                                                        name="bank"
                                                        errors={errors}
                                                        options={props.listInformationBancaire}
                                                        onChange={handleChangeAffiliationInfBank}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            )}

                                            <FormGroup>
                                                <IntlMessages id="crm.client.contact.bank.details.owner"/>
                                                <AdaInputText
                                                    name="owner"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="crm.client.contact.bank.details.designation"/>
                                                <AdaInputText
                                                    name="designation"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="crm.client.contact.bank.details.iban"/>
                                                <AdaInputText
                                                    name="iban"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="crm.client.contact.bank.details.rib"/>
                                                        <AdaInputText
                                                            name="rib"
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="crm.client.contact.bank.details.bic"/>
                                                        <AdaInputText
                                                            name="bic"
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup>
                                                <AdaButton type="submit"
                                                           className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                >
                                                    <IntlMessages id="action.common.create.client"/>
                                                </AdaButton>
                                                <AdaButton onClick={() => props.step("5")}
                                                           className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.common.previous.contact"/>
                                                </AdaButton>
                                                <AdaButton
                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    onClick={props.cancelAction}
                                                >
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
