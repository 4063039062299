import React from "react";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import axios from "axios";
import IntlMessages from "../../../helpers/intlMessages";
import {CRM_NAME, getHostByAppName} from "../../../service/host";


export default function ActivityAreaForm(props) {

    const {handleSubmit, register, errors, setValue} = useForm();
    const [activityArea, setActivityArea] = React.useState({});
    const [activityAreasId, setActivityAreasId] = React.useState(initActivityAreasId());
    const [errorMessage, setErrorMessage] = React.useState("");
    let history = useHistory();

    function initActivityAreasId() {
        const {
            match: {params}
        } = props;
        console.log()
        return params.activityAreasId;
    }

    React.useEffect(() => {
        if (activityAreasId !== undefined)
            axios.get(`${getHostByAppName(CRM_NAME)}/activity_areas/${activityAreasId}`).then(res => {
                setActivityArea(res.data);
            });
    }, [activityAreasId]);

    React.useEffect(() => {
        register({name: 'name'}, {required: 'Ce champ est obligatoire.'});
        register({name: 'activityCode'}, {required: 'Ce champ est obligatoire.', pattern: /AA-\S+$/i});
    }, []);

    const onSubmit = data => {
        console.log(data);
        if (activityAreasId !== undefined) {
            axios.put(`${getHostByAppName(CRM_NAME)}/activity_areas/part/${activityAreasId}/`, data).then(res => {
                history.push("/activity_areas")
            }).catch(function (error) {
                setErrorMessage(error.toString());
            });
        } else {
            axios.post(`${getHostByAppName(CRM_NAME)}/activity_areas/`, data).then(res => {
                history.push("/activity_areas")
            }).catch(function (error) {
                setErrorMessage(error.toString());
            })
        }
    };


    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col>
                                        <Label>
                                            <p style={{color: "red"}}> {errorMessage}</p>
                                        </Label>
                                        <FormGroup>
                                            <IntlMessages
                                                id="crm.activityAreas.code"
                                            />
                                            <AdaInputText
                                                placeholder="Start with AA-..."
                                                name="activityCode"
                                                defaultValue={activityArea.activityCode}
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <IntlMessages
                                                id="crm.activityAreas.name"
                                            />
                                            <AdaInputText
                                                name="name"
                                                defaultValue={activityArea.name}
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <AdaButton
                                                className = "no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right  ml-sm-2"
                                                type="submit">
                                                <IntlMessages
                                                    id="action.common.submit"
                                                />
                                            </AdaButton>
                                            <AdaButton
                                                className = "no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                style={{marginLeft: 10}}
                                                onClick={() => history.push("/activity_areas")}
                                            >
                                                <IntlMessages
                                                    id="action.common.cancel"
                                                />
                                            </AdaButton>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
