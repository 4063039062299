import Contacts from "../views/crm/contact/contacts"
import ContactForm from "../views/crm/contact/contactForm"

const conctactsRoutes = [
    {path: "/contacts", component: Contacts},
    {path: "/contacts/new", component: ContactForm},
    {path: "/contacts/:contactId/edit", component: ContactForm},

];

export default conctactsRoutes;