import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {columnsActivityArea} from "./data/columns"
import IntlMessages from "../../../helpers/intlMessages";
import {AdaActions, AdaFilterSubHeader, AdaTable} from "@adaming/ada-react-component";
import SweetAlert from "react-bootstrap-sweetalert";
import {CRM_NAME, getHostByAppName} from "../../../service/host";

const context = "activity_areas";

const ActivityArea = props => {
    const [activityAreas, setActivityAreas] = React.useState([]);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);

    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.delete`}/>,
                href: "",
                actionHandler: handlerOpenModalDelete,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.edit`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            }
        ];
    };

    React.useEffect(() => {
        axios.get(`${getHostByAppName(CRM_NAME)}/activity_areas/`).then(res => {
            setActivityAreas(res.data);
            setFiltered(res.data);
        });
    }, [modalSuccessShow]);

    React.useEffect(() => {
        let actionsValue = initActionsList();
        setActionsList(actionsValue);
        if (selectedRows !== undefined && selectedRows.length > 0) {
            /*Activate : Delete Action*/
            actionsValue[0] = {...actionsValue[0], disabled: false};
            if (selectedRows.length === 1) {
                /*Activate : Edit Action*/
                actionsValue[1] = {
                    ...actionsValue[1],
                    href: `/${context}/${selectedRows[0].id}/edit`,
                    disabled: false
                };
            }
            setActionsList(actionsValue);
            console.log("actions list : ", actionsValue);
        }
    }, [selectedRows.length]);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handlerDeleteAction = () => {
        console.log("in Delete action", selectedRows);
        selectedRows.forEach(element =>
            axios
                .delete(`${getHostByAppName(CRM_NAME)}/${context}/${element.id}`)
                .then(res => {
                    console.log(res);
                })
        );
        setModalShow(false);
        setModalSuccessShow(true);
    };

    const handlerOpenModalDelete = () => {
        setModalShow(true);
    };

    const actionsComponent = (
        <AdaActions context={context} actionsObj={actionsList}/>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsActivityArea}
            items={activityAreas}
            setFiltredItems={setFiltered}
        />
    );

    return (<div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsActivityArea}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                            />
                            <SweetAlert
                                onClick={props.processAction}
                                warning
                                showCancel
                                show={modalShow}
                                confirmBtnText="Oui"
                                confirmBtnBsStyle="no-border c-AdaNext c-btn-wide"
                                cancelBtnBsStyle="no-border c-outline-AdaCancel c-btn-wide"
                                title="Confirmation"
                                onConfirm={() => handlerDeleteAction()}
                                onCancel={() => setModalShow(false)}
                            >
                                <IntlMessages id="action.common.confirmation"/>
                            </SweetAlert>

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Succès!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="no-border c-AdaNext c-btn-wide "
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(ActivityArea);
