import React, {useEffect, useState} from "react";
import {
    FormGroup
} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import BoardList from "../../../../components/scrumboard/BoardList";
import ContactForm from "../../../../components/scrumboard/ContactForm";
import ScrumboardWrapper from "../../../../components/scrumboard/scrumboard.style";
import scrumActions from "../../../../redux/scrumboard/actions";
import {connect} from "react-redux";

const context = "contacts";
const {updateBoards} = scrumActions;

const ClientContacts = props => {

    const [listResponsible, setListResponsible] = React.useState([]);
    const [mapEntitiesWithContact, setMapEntitiesWithContact] = useState(
        new Map()
    );
    const [currentModelAction, setCurrentModelAction] = useState("add");
    const [editedContent, setEditedContent] = useState({});
    const [isAddBoard, setIsAddBoard] = useState(false);
    const [contactModel, setContactModel] = useState(false);

    const addNewContact = data => {
        console.log("on submit data", data);
        console.log("on submit editedContent", editedContent);
        console.log("on submit add contact", props.listContacts);
        let contactObj = {
            civility: data.civility,
            actif: data.actif,
            responsible: data.responsible,
            firstName: data.firstName,
            lastName: data.lastName,
            function: data.function,
            category: data.category,
            owner: "admin",
            contactInformation: {
                phone: data.phone,
                mobile: data.mobile,
                email: data.email,
                fax: data.fax
            },
            address: {...data.address}
        };

        setListResponsible(
            listResponsible.concat({
                key: contactObj,
                label: data.firstName + " " + data.lastName,
                value: data.firstName + " " + data.lastName,
                object: contactObj
            })
        );

        if (editedContent.id !== undefined && currentModelAction === "edit") {
            console.log("edit", editedContent);
            if (data.entitie !== undefined && editedContent.idEntity !== undefined) {
                let newList = replaceElementInArray(
                    props.listContactsByEntitie,
                    {entity: data.entitie, obj: contactObj},
                    editedContent.id
                );
                console.log("list", newList);
                props.setListContactsByEntitie(newList);
            } else if (
                data.entitie === undefined &&
                editedContent.idEntity === undefined
            ) {
                let newList = replaceElementInArray(
                    props.listContacts,
                    contactObj,
                    editedContent.id
                );
                console.log("list", newList);
                props.setListContacts(newList);
            } else if (
                data.entitie === undefined &&
                editedContent.idEntity !== undefined
            ) {
                //delete from listContactsByEntitie and add to listContacts
                props.setListContactsByEntitie(
                    deleteElementFromList(editedContent.id, props.listContactsByEntitie)
                );
                props.setListContacts(props.listContacts.concat(contactObj));
            } else if (
                data.entitie !== undefined &&
                editedContent.idEntity === undefined
            ) {
                //delete from listContacts and add to listContactsByEntitie
                props.setListContacts(
                    deleteElementFromList(editedContent.id, props.listContacts)
                );
                props.setListContactsByEntitie(
                    props.listContactsByEntitie.concat({
                        entity: data.entitie,
                        obj: contactObj
                    })
                );
            }
        } else {
            console.log("create data", data);
            if (data.entitie !== undefined) {
                console.log("create data entity", data);
                props.setListContactsByEntitie(
                    props.listContactsByEntitie.concat({
                        entity: data.entitie,
                        obj: contactObj
                    })
                );
            } else {
                props.setListContacts(props.listContacts.concat(contactObj));
            }
        }
        setCurrentModelAction("");
        setEditedContent({});
        setContactModel(false);
    };

    const replaceElementInArray = (oldList, object, index) => {
        let newList = Object.assign([], oldList, {
            [index]: object
        });
        return newList;
    };

    const updateClientWithContactsList = () => {
        let entitiesListToSetClient = [];
        let updateListContactsByEntitie = [];
        mapEntitiesWithContact.forEach((objectValue, objectKey) => {
            console.log("object key", objectKey);
            console.log("object value", objectValue);
            entitiesListToSetClient.push({
                ...objectKey,
                contacts: objectValue.map(objectContact => objectContact.obj)
            });
        });
        console.log("list Entities", entitiesListToSetClient);
        props.updateAction({
            contacts: props.listContacts,
            entities: entitiesListToSetClient
        });
    };

    const nextStep = () => {
        updateClientWithContactsList();
        props.step("6");
    };

    const previousStep = () => {
        updateClientWithContactsList();
        props.step("4");
    };

    const closeForm = () => {
        setCurrentModelAction("");
        setEditedContent({});
        setContactModel(false);
    };

    useEffect(
        () => {
            console.log("in effect change entities list from contact");
            if (props.clientObj.entities !== undefined) {
                let map = new Map();
                props.clientObj.entities.forEach(entitie =>
                    map.set(
                        entitie,
                        props.listContactsByEntitie.filter(
                            obj =>
                                obj !== null &&
                                obj !== undefined &&
                                entitie.idEntity === obj.entity.idEntity
                        )
                    )
                );
                console.log(
                    "in effect change entities list from contact to print Map",
                    map
                );

                console.log(
                    "in effect change entities list from contact to print list",
                    props.listContactsByEntitie
                );
                setMapEntitiesWithContact(map);
            } else {
                setMapEntitiesWithContact(new Map());
            }
        },
        [props.clientObj.entities, props.listContactsByEntitie]
    );

    useEffect(() => {
    }, [props.listContacts, props.listContactsByEntitie]);


    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }
        });
        return arr;
    };

    const contactToggleModel = () => {
        setContactModel(!contactModel);
    };

    const actiononContact = (action, object) => {
        console.log("e", object);
        if (action === "add") {
            setCurrentModelAction("add");
            setEditedContent({});
            setContactModel(true);
        } else if (action === "edit") {
            setCurrentModelAction("edit");
            setEditedContent(object);
            setContactModel(true);
        }
    };

    useEffect(() => {
        console.log("first loading in contact");
    }, []);

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">
                                <ScrumboardWrapper {...props}>
                                    <div>
                                        <div className="pt-10">
                                            <div className="fs-20 bold-text ml-15 pb-15 board-type-head">
                                                Personal Boards
                                            </div>
                                            <div className="row ma-0">
                                                {props.listContacts.map((board, i) => {
                                                    return (
                                                        <BoardList
                                                            key={i}
                                                            unique="personal"
                                                            board={{id: i, ...board}}
                                                            actiononContact={() => {
                                                                setEditedContent({id: i, ...board});
                                                                actiononContact("edit", {id: i, ...board});
                                                            }}
                                                            deleteBoardHandler={() =>
                                                                props.setListContacts(
                                                                    deleteElementFromList(i, props.listContacts)
                                                                )
                                                            }
                                                        />
                                                    );
                                                })}

                                                {props.listContactsByEntitie.map((board, i) => {
                                                    return (
                                                        <BoardList
                                                            key={i}
                                                            unique="personal"
                                                            board={{id: i, ...board.obj, ...board.entity}}
                                                            actiononContact={() => {
                                                                setEditedContent({
                                                                    id: i,
                                                                    ...board.obj,
                                                                    ...board.entity
                                                                });
                                                                actiononContact("edit", {
                                                                    id: i,
                                                                    ...board.obj,
                                                                    ...board.entity
                                                                });
                                                            }}
                                                            deleteBoardHandler={() =>
                                                                props.setListContactsByEntitie(
                                                                    deleteElementFromList(
                                                                        i,
                                                                        props.listContactsByEntitie
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    );
                                                })}

                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-15 ">
                                                    <div
                                                        className="whitelight pa-24 cursor-pointer with-transition roe-box-shadow">
                                                        <div className="board">
                                                            <div
                                                                className="overlay flex-x center"
                                                                onClick={() => actiononContact("add", null)}
                                                            >
                                                                <div>
                                                                    <div className="text-center">
                                                                        {isAddBoard ? (
                                                                            <div/>
                                                                        ) : (
                                                                            <div
                                                                                className="fs-20 bold-text board-list-title">
                                                                                <i className="fas fa-plus"/>{" "}
                                                                                <IntlMessages
                                                                                    id="crm.common.create.contact"/>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ScrumboardWrapper>
                                {contactModel && (
                                    <ContactForm
                                        currentModelAction={currentModelAction}
                                        editObject={editedContent}
                                        addNewItem={addNewContact}
                                        dispatch={props.dispatch}
                                        selectedObjects={props.selectedObjects}
                                        close={closeForm}
                                        listResponsible={listResponsible}
                                        listEntities={props.listEntities}
                                        showContactModal={contactModel}
                                    />
                                )}
                                <FormGroup>
                                    <AdaButton
                                        onClick={nextStep}
                                        className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right  ml-sm-2"
                                    >
                                        <IntlMessages id="action.common.next.bank.details"/>
                                    </AdaButton>
                                    <AdaButton
                                        onClick={previousStep}
                                        className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right  ml-sm-2"
                                    >
                                        <IntlMessages id="action.common.previous.entities"/>
                                    </AdaButton>
                                    <AdaButton
                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                        onClick={props.cancelAction}
                                    >
                                        <IntlMessages id="action.common.cancel"/>
                                    </AdaButton>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        boards: state.scrumboard.boards
    };
};

export default connect(
    mapStateToProps,
    {
        updateBoards
    }
)(ClientContacts);
