import React, {useEffect, useState} from "react";
import { Col, CustomInput, Row, FormGroup } from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import { AdaSelectBuilder } from "@adaming/ada-react-business-component";
import { getHostByAppName, NOMENCLATURE_NAME } from "../../../../service/host";
import {Controller} from "react-hook-form";
import * as service from "../../../../service/crud.service";
import UserInfoWrapper from "./userinfo.style";

export default function UpdateBankInformation({icon, title, register, errors, client}){

    return (
        <div>


            <UserInfoWrapper className="fill-height">
                <div className="card roe-shadow-2 fill-height">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-md-4 aligner-wrapper text-center">
                                <img
                                    src={icon}
                                    style={{width:"140px"}}
                                />
                                <div style={{marginTop:"10px"}}>
                                    <h3 className="card-title fs-25 bold-text">{title}</h3>
                                </div>
                            </div>
                            <div className="col-md-4 legend-wrapper">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                        <h4 className="mb-0 text-dark fs-18 bold-text" >{client.bankDetails && client.bankDetails.owner ? client.bankDetails.owner : "ND"}</h4>
                                    </div>
                                    <small className="text-muted ml-3">Titulaire</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="bankDetails.rib"
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={ client.bankDetails && client.bankDetails.rib ? client.bankDetails.rib : ""}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">RIB</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="bankDetails.iban"
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={client.bankDetails && client.bankDetails.iban ? client.bankDetails.iban : ""}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">IBAN</small>
                                </div>
                            </div>

                            <div className="col-md-4 legend-wrapper">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="bankDetails.designation"
                                            errors={errors}
                                            defaultValue={client.bankDetails && client.bankDetails.designation ? client.bankDetails.designation : ""}
                                            innerRef={register}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Banque</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="bankDetails.bic"
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={client.bankDetails && client.bankDetails.bic ? client.bankDetails.bic : ""}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">BIC</small>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </UserInfoWrapper>
        </div>
    );
}
