import { themeConfig } from "./index";

const changeThemes = {
    id: "changeThemes",
    label: "themeChanger",
    defaultTheme: themeConfig.theme,
    options: [
        {
            themeName: "themedefault",
            buttonColor: "#ffffff",
            textColor: "#323332"
        }
    ]
};

const topbarTheme = {
    id: "topbarTheme",
    label: "themeChanger.topbar",
    defaultTheme: themeConfig.topbar,
    options: [
        {
            themeName: "themedefault",
            buttonBackColor: "#f6f6f6",
            buttonColor: "#666666",
            textColor: "#323332",
            backgroundColor: "#ffffff"
        },
        {
            themeName: "theme1",
            buttonBackColor: "#898E90",
            buttonColor: "#fff",
            backgroundColor: "#273135",
            textColor: "#ffffff"
        },
        {
            themeName: "theme2",
            buttonBackColor: "#EBEAED",
            buttonColor: "#666666",
            backgroundColor: "#241d3b",
            textColor: "#ffffff"
        },
        {
            themeName: "theme3",
            buttonBackColor: "#F0F1F1",
            buttonColor: "#666666",
            backgroundColor: "#61696C",
            textColor: "#ffffff"
        },
        {
            themeName: "theme4",
            buttonBackColor: "#414141",
            buttonColor: "#fff",
            backgroundColor: "#EBECEC",
            textColor: "#000000"
        },
        {
            themeName: "theme5",
            buttonBackColor: "#fff",
            buttonColor: "#818181",
            backgroundColor: "#D6D7D7",
            textColor: "#000"
        },
        {
            themeName: "theme6",
            buttonBackColor: "#9D7CA9",
            buttonColor: "#fff",
            backgroundColor: "#794c8a",
            textColor: "#ffffff"
        },
        {
            themeName: "theme7",
            buttonBackColor: "#75678C",
            buttonColor: "#fff",
            backgroundColor: "#422e62",
            textColor: "#ffffff"
        }
    ]
};

const sidebarTheme = {
    id: "sidebarTheme",
    label: "themeChanger.sidebar",
    defaultTheme: themeConfig.sidebar,
    options: [
        {
            themeName: "themedefault",
            buttonColor: "#2a2d35",
            backgroundColor: "#2a2d35",
            textColor: "#ffffff",
            activeRouteTextColor: "#FFF",
            activeRouteBackColor: "#f6a821",
            style4VarientActiveTextColor: "#f6a821"
        },
        {
            themeName: "theme1",
            buttonColor: "#273135",
            backgroundColor: "#273135",
            textColor: "#ffffff",
            activeRouteTextColor: "#FFF",
            activeRouteBackColor: "#FA7252",
            style4VarientActiveTextColor: "#FA7252"
        },
        {
            themeName: "theme2",
            buttonColor: "#FFF",
            backgroundColor: "#FFF",
            textColor: "#000000",
            activeRouteTextColor: "#FFF",
            activeRouteBackColor: "#68B3C8",
            // header: "#EBEBEB",
            style4VarientActiveTextColor: "#68B3C8"
        },
        {
            themeName: "theme3",
            buttonColor: "#241d3b",
            backgroundColor: "#241d3b",
            textColor: "#ffffff",
            activeRouteTextColor: "#ffffff",
            activeRouteBackColor: "#6200ea",
            style4VarientActiveTextColor: "#B78BF5",
        },
        {
            themeName: "theme4",
            buttonColor: "#422e62",
            backgroundColor: "#422e62",
            textColor: "#ffffff",
            activeRouteTextColor: "#ffffff",
            activeRouteBackColor: "#75678C",
            style4VarientActiveTextColor: "#D2E690"
        },
        {
            themeName: "theme5",
            buttonColor: "#273135",
            backgroundColor: "#273135",
            textColor: "#ffffff",
            activeRouteTextColor: "#FFF",
            activeRouteBackColor: "#F6323E",
            style4VarientActiveTextColor: "#F6323E"
        },
        // {
        //   themeName: 'theme5',
        //   buttonColor: '#6c757d',
        //   backgroundColor: '#6c757d ',
        //   textColor: '#ffffff',
        //   activeRouteTextColor: '#4F565B',
        //   aRouteBackctiveColor: '#4F565B',
        //   style4VarientActiveTextColor: '#282B2E'
        // },
        // {
        //   themeName: 'theme6',
        //   buttonColor: '#DDD2E1',
        //   backgroundColor: '#DDD2E1',
        //   textColor: '#000',
        //   activeRouteTextColor: '#9D7CA9',
        //   aRouteBackctiveColor: '#9D7CA9',
        //   style4VarientActiveTextColor: '#E91E63'
        // },
        // {
        //   themeName: 'theme7',
        //   buttonColor: '#273135',
        //   backgroundColor: '#273135',
        //   textColor: '#ffffff',
        //   activeRouteTextColor: '#161B1D',
        //   aRouteBackctiveColor: '#161B1D',
        //   header: '#161B1D',
        //   style4VarientActiveTextColor: '#F2C291'
        // },
        // {
        //   themeName: 'theme8',
        //   buttonColor: '#273135',
        //   backgroundColor: '#ffffff',
        //   textColor: '#000000',
        //   activeRouteTextColor: '#2DE1C2',
        //   aRouteBackctiveColor: '#2DE1C2',
        //   style4VarientActiveTextColor: '#2DE1C2'
        // }
    ]
};

const layoutTheme = {
    id: "layoutTheme",
    label: "themeChanger.background",
    defaultTheme: themeConfig.layout,
    options: [
        {
            themeName: "themedefault",
            buttonColor: "#fafafa",
            backgroundColor: "#fafafa",
            cardBackground: "#fff",
            headingColor: "#6a727a",
            textColor: "#000"
        },
        {
            themeName: "theme1",
            buttonColor: "#ffffff",
            backgroundColor: "#ffffff",
            cardBackground: "#fff",
            headingColor: "#6a727a",
            textColor: "#000"
        },
        {
            themeName: "theme2",
            buttonColor: "#F9F9F9",
            backgroundColor: "#F9F9F9",
            cardBackground: "#fff",
            headingColor: "#6a727a",
            textColor: "#000"
        },
        {
            themeName: "theme3",
            buttonColor: "#ebebeb",
            backgroundColor: "#ebebeb",
            cardBackground: "#fff",
            headingColor: "#6a727a",
            textColor: "#000"
        },
        {
            themeName: "theme4",
            buttonColor: "#D9D9D9",
            backgroundColor: "#D9D9D9",
            cardBackground: "#fff",
            headingColor: "#6a727a",
            textColor: "#000"
        },
        {
            themeName: "theme5",
            buttonColor: "#eef5f9",
            backgroundColor: "#eef5f9",
            cardBackground: "#fff",
            headingColor: "#000",
            textColor: "#000"
        },
        {
            themeName: "theme6",
            buttonColor: "#2d3038",
            backgroundColor: "#2d3038",
            cardBackground: "rgba(68, 70, 79, 0.5)",
            headingColor: "#fff",
            textColor: "#949ba2"
        }
    ]
};

const footerTheme = {
    id: "footerTheme",
    label: "themeChanger.footer",
    defaultTheme: themeConfig.footer,
    options: [
        {
            themeName: "themedefault",
            buttonBackColor: "#f6f6f6",
            buttonColor: "#666666",
            textColor: "#323332",
            backgroundColor: "#ffffff"
        },
        {
            themeName: "theme1",
            buttonBackColor: "#898E90",
            buttonColor: "#fff",
            backgroundColor: "#273135",
            textColor: "#ffffff"
        },
        {
            themeName: "theme2",
            buttonBackColor: "#EBEAED",
            buttonColor: "#666666",
            backgroundColor: "#241d3b",
            textColor: "#ffffff"
        },
        {
            themeName: "theme3",
            buttonBackColor: "#F0F1F1",
            buttonColor: "#666666",
            backgroundColor: "#61696C",
            textColor: "#ffffff"
        },
        {
            themeName: "theme4",
            buttonBackColor: "#414141",
            buttonColor: "#fff",
            backgroundColor: "#EBECEC",
            textColor: "#000000"
        },
        {
            themeName: "theme5",
            buttonBackColor: "#fff",
            buttonColor: "#818181",
            backgroundColor: "#D6D7D7",
            textColor: "#000"
        },
        {
            themeName: "theme6",
            buttonBackColor: "#9D7CA9",
            buttonColor: "#fff",
            backgroundColor: "#794c8a",
            textColor: "#ffffff"
        },
        {
            themeName: "theme7",
            buttonBackColor: "#75678C",
            buttonColor: "#fff",
            backgroundColor: "#422e62",
            textColor: "#ffffff"
        }
    ]
};

const customizedThemes = {
    changeThemes,
    topbarTheme,
    sidebarTheme,
    layoutTheme,
    footerTheme
};

export function getCurrentTheme(attribute, selectedThemename) {
    let selecetedTheme = {};
    customizedThemes[attribute].options.forEach(theme => {
        if (theme.themeName === selectedThemename) {
            selecetedTheme = theme;
        }
    });
    return selecetedTheme;
}
export default customizedThemes;
