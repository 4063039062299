import React from "react";
import {Col, Row, Toast, ToastBody, ToastHeader} from "reactstrap";

export default function EntitiesDetails(props) {
    return (
        <div>
            <Row>
                {props.client.entities !== undefined &&
                (props.client.entities.length > 0 &&
                    props.client.entities.map(entity =>
                        <Col className="col-sm-6 ml-sm-3">
                                <span className="chip mtb-6">
                                    {entity.name}
                                </span>
                            <Row>
                                {entity.contacts !== undefined &&
                                (entity.contacts.length > 0 &&
                                    entity.contacts.map(contact => (
                                            <Col>
                                                <Toast>
                                                    <ToastHeader style={{color: "#b71c1c"}}>
                                                        {entity.name} :{" "}
                                                        {contact.civility !== undefined && contact.civility}.{" "}
                                                        {contact.firstName !== undefined &&
                                                        contact.firstName}{" "}
                                                        {contact.lastName}
                                                    </ToastHeader>
                                                    <ToastBody>
                                                        Téléphone :{" "}
                                                        {contact.contactInformation !== undefined &&
                                                        (contact.contactInformation.phone !== undefined &&
                                                            contact.contactInformation.phone)}
                                                        <br/>
                                                        email :{" "}
                                                        {contact.contactInformation !== undefined &&
                                                        (contact.contactInformation.email !== undefined &&
                                                            contact.contactInformation.email)}
                                                    </ToastBody>
                                                </Toast>
                                            </Col>
                                        )
                                    ))}
                            </Row>
                        </Col>
                    ))}
            </Row>
        </div>
    );
}
