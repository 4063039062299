import React, {useEffect, useState} from "react";
import {TabContent, TabPane, Nav, NavItem, NavLink, Col, Row} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../../helpers/intlMessages";
import GenericAddNote from "../../../commun/genericAddNote";
import EntitiesDetails from "./entitiesDetails";
import ContactsDetails from "./contactsDetails";
import SweetAlert from "react-bootstrap-sweetalert";
import {CRM_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../../../service/host";
import * as service from "../../../../service/crud.service";
import LatestActivity from "../adaClientUpdate/UserInfoDoughnutWidget";
import {addressPostal, buildingEnterprise, infoban, infoFisc} from "../../../../helpers/constants";
import WrapperInformation from "../adaClientUpdate/wrapper";
import DisplayInformationStyle from "../adaClientUpdate/displayInformationStyle";

export default function ClientsDetails({hideDetails, selectedElement}) {
    const [activeTab, setActiveTab] = useState("1");
    const [client, setClient] = React.useState({});
    const [modalShow, setModalShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    const [displayInformationList, setDisplayInformationList] = useState([]);
    const [activityArea, setActivityArea] = useState({})
    const [address, setAddress] = useState({});

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(
        () => {
            console.log("Hide client details :", hideDetails);
            if (!hideDetails) {
                let idSelectedElement = "";
                if (selectedElement !== null && selectedElement !== undefined) {
                    idSelectedElement = selectedElement.id;
                    console.log("client Id for details :", idSelectedElement);
                    service.getById(
                        getHostByAppName(CRM_NAME),
                        `clients`,
                        callbackGetById,
                        error => console.log(error), idSelectedElement
                    );
                }
            }
        },
        [hideDetails]
    );

    const callbackGetById = data=>{
        setClient(data);
        if(data.activityArea){
            service.getById(
                getHostByAppName(NOMENCLATURE_NAME),
                `company_activity_areas`,
                setActivityArea,
                error => console.log(error), data.activityArea
            );
        }
    }

    React.useEffect(() => {
    }, [modalSuccessShow]);

    /*const handlerSaveNoteAction = data => {
        console.log("data note : ", data);
        let tab = [];
        tab.push({subject: data.subject, text: data.text});
        if (client.notes !== undefined && client.notes.length > 0) {
            console.log("client note not empty : ", client.notes);
            client.notes.forEach(note => tab.push(note));
        }
        console.log("data note tab: ", {
            notes: tab
        });
        axios
            .put(`${getHostByAppName(CRM_NAME)}/clients/part/${client.id}`, {
                notes: tab
            })
            .then(res => {
                console.log("getClient after update rest service http : ", res.data);
                setClient(res.data);
                setModalShow(false);
                setModalSuccessShow(true);
            });
    };*/


    useEffect(() => {
        if (client.id !== undefined) {
            service.get(
                getHostByAppName(CRM_NAME),
                `clients/${client.id}/address`,
                callbackResult,
                (error)=> console.log("error",error)
            );

        }
    }, [client]);

    const callbackResult = (data) =>{
        console.log("dddddd",data)
        data && data.length> 0 && data[0].id ? setAddress(data[0])
            : setAddress({
                city: "",
                country: "",
                district: "",
                streetName: "",
                streetNumber: "",
                zipCode: "",
            })
    }

    return (
        !hideDetails && (
            <div>
                <Nav tabs>
                    <NavItem className="cursor-pointer">
                        <NavLink
                            className={classnames({active: activeTab === "1"})}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            <IntlMessages id={`crm.common.information`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink
                            className={classnames({active: activeTab === "2"})}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            <IntlMessages id={`crm.common.mailing.address`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink
                            className={classnames({active: activeTab === "3"})}
                            onClick={() => {
                                toggle("3");
                            }}
                        >
                            <IntlMessages id={`crm.common.information.fiscal`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink
                            className={classnames({active: activeTab === "4"})}
                            onClick={() => {
                                toggle("4");
                            }}
                        >
                            <IntlMessages id={`crm.common.entities`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink
                            className={classnames({active: activeTab === "5"})}
                            onClick={() => {
                                toggle("5");
                            }}
                        >
                            <IntlMessages id={`crm.common.contacts`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink
                            className={classnames({active: activeTab === "6"})}
                            onClick={() => {
                                toggle("6");
                            }}
                        >
                            <IntlMessages id={`crm.common.bank.details`}/>
                        </NavLink>
                    </NavItem>
                    {/*<NavItem className="cursor-pointer">
                        <NavLink
                            className={classnames({active: activeTab === "7"})}
                            onClick={() => {
                                toggle("7");
                            }}
                        >
                            <IntlMessages id={`crm.common.notes`}/>
                        </NavLink>
                    </NavItem>*/}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        {client && client.id && <LatestActivity
                            icon={buildingEnterprise}
                            title={client.name}
                            description={client.description ? client.description : "ND"}
                            site={client.web ? client.web : "ND"}
                            telephone={client.contactInformation && client.contactInformation.phone ? client.contactInformation.phone : "ND"}
                            email={client.contactInformation && client.contactInformation.email ? client.contactInformation.email : "ND"}
                            fax={client.contactInformation && client.contactInformation.fax ? client.contactInformation.fax : "ND"}
                            statut={client.enable === true ? "Activé" : "Désactivé"}
                            owner={client.owner}
                            activityArea={client.activityArea}
                            group={client.group? client.group : "ND"}

                        />}
                    </TabPane>
                    <TabPane tabId="2">
                        {client && client.id && <WrapperInformation title={<IntlMessages
                            id="crm.common.mailing.address"/>}
                                                                    icon={addressPostal}
                                                                    informationLigne1={address.id ? (address.streetNumber + " " + address.streetName):"ND"}
                                                                    informationLigne2={address.zipCode + " " + address.city + " " +address.country}/>}
                    </TabPane>
                    <TabPane tabId="3">
                        {client && client.id && <DisplayInformationStyle  icon={infoFisc}
                                                   title={"Coordonnées fiscales"}
                                                   inf1={{title : "Code RCS", value : client.administrationInformation && client.administrationInformation.rcs ? client.administrationInformation.rcs : "ND"}}
                                                   inf4={{title : "Siret", value : client.administrationInformation && client.administrationInformation.siret ? client.administrationInformation.siret : "ND"}}
                                                   inf2={{title : "Numéro d'enregistrement", value : client.administrationInformation && client.administrationInformation.registrationNumber ? client.administrationInformation.registrationNumber : "ND"}}
                                                   inf5={{title : "Tva intracommunautaire", value : client.administrationInformation && client.administrationInformation.vatNumberIntracommunity ? client.administrationInformation.vatNumberIntracommunity : "ND"}}
                        />}
                    </TabPane>
                    <TabPane tabId="4">
                        <Col sm="15">
                            <EntitiesDetails client={client}/>
                        </Col>
                    </TabPane>
                    <TabPane tabId="5">
                        <Col sm="15">
                            <ContactsDetails client={client}/>
                        </Col>
                    </TabPane>
                    <TabPane tabId="6">
                        {client && client.id && <DisplayInformationStyle  icon={infoban}
                                                   title={"Coordonnées bancaires"}
                                                   inf1={{title : "Titulaire", value : client.bankDetails && client.bankDetails.owner ? client.bankDetails.owner : "ND"}}
                                                   inf4={{title : "Banque", value : client.bankDetails && client.bankDetails.designation ? client.bankDetails.designation : "ND"}}
                                                   inf2={{title : "RIB", value : client.bankDetails && client.bankDetails.rib ? client.bankDetails.rib : "ND"}}
                                                   inf5={{title : "BIC", value : client.bankDetails && client.bankDetails.bic ? client.bankDetails.bic : "ND"}}
                                                   inf3={{title : "IBAN", value : client.bankDetails && client.bankDetails.iban ? client.bankDetails.iban : "ND"}}
                        />}
                    </TabPane>
                   {/* <TabPane tabId="7">
                        <NotesDetails client={client} onClick={() => setModalShow(true)}/>
                    </TabPane>*/}
                </TabContent>
                {/*<GenericAddNote
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    processAction={handlerSaveNoteAction}
                />*/}
                <SweetAlert
                    success
                    show={modalSuccessShow}
                    title="Succès!"
                    onConfirm={() => setModalSuccessShow(false)}
                    confirmBtnCssClass="sweet-alert-confirm-button"
                    cancelBtnCssClass="sweet-alert-cancle-button"
                >
                    <IntlMessages id="action.common.succes"/>
                </SweetAlert>
            </div>
        )
    );
}
