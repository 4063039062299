import React from "react";
import Modal from "react-bootstrap/Modal";
import { AdaButton } from "@adaming/ada-react-component";

export default function GenericSuccessModal(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Success
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Centered Modal</h4>
                <p>
                    Success
                </p>
            </Modal.Body>
            <Modal.Footer>
                <AdaButton onClick={props.onHide}>Close</AdaButton>
            </Modal.Footer>
        </Modal>
    );
}
