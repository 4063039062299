import React, {useEffect} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import AdaAddressBuilder from "../../../commun/ada.address.builder";
import {addressPostal, buildingEnterprise} from "../../../../helpers/constants";

export default function ClientAdressePostal(props) {
    const {register, handleSubmit, errors, setValue, control} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    let history = useHistory();

    const onSubmit = data => {
        props.updateAction({...data});
        props.step("3");
    };

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={addressPostal}
                                         style={{width:"120px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Adresse</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <br/>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>

                                            <AdaAddressBuilder
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                inputName={"address"}
                                                defaultAddress={props.clientObj.address}
                                                control={control}
                                                dispatch={props.dispatch}
                                                selectedObjects={props.selectedObjects}
                                            />
                                            <FormGroup>
                                                <AdaButton
                                                    type="submit"
                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                >
                                                    <IntlMessages id="action.common.next.information.fiscal"/>
                                                </AdaButton>
                                                <AdaButton
                                                    onClick={() => props.step("1")}
                                                    className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                >
                                                    <IntlMessages id="action.common.previous.information"/>
                                                </AdaButton>
                                                <AdaButton
                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    onClick={props.cancelAction}
                                                >
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
