import React, {useEffect, useState} from "react";
import { Col, CustomInput, Row, FormGroup } from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import { AdaSelectBuilder } from "@adaming/ada-react-business-component";
import { getHostByAppName, NOMENCLATURE_NAME } from "../../../../service/host";
import {Controller} from "react-hook-form";
import * as service from "../../../../service/crud.service";
import UserInfoWrapper from "./userinfo.style";

export default function UpdateAddressInformation({icon, title, register, errors, address}){

    useEffect(()=>{

    },[])

    return (
        <div>
            <UserInfoWrapper className="fill-height">
                <div className="card roe-shadow-2 fill-height">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-md-4 aligner-wrapper text-center">
                                <img
                                    src={icon}
                                    style={{width:"140px"}}
                                />
                                <div style={{marginTop:"10px"}}>
                                    <h3 className="card-title fs-25 bold-text">{title}</h3>
                                </div>
                            </div>
                            <div className="col-md-4 legend-wrapper">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                        <AdaInputText
                                            type="number"
                                            name="streetNumber"
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.streetNumber}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">N°</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="streetName"
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.streetName}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Adresse</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="city"
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.city}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Ville</small>
                                </div>
                            </div>

                            <div className="col-md-4 legend-wrapper">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="zipCode"
                                            errors={errors}
                                            defaultValue={address.zipCode}
                                            innerRef={register}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Code postal</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                        <AdaInputText
                                            name="district"
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.district}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Région</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                        <AdaInputText
                                            id={"countryId"}
                                            name={"country"}
                                            errors={errors}
                                            defaultValue={address !== undefined ? address.country : ""}
                                            innerRef={register}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Pays</small>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </UserInfoWrapper>
        </div>
    );
}
