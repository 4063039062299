import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const columnsActivityArea = [
    {
        name: <IntlMessages
            id="crm.client.code"
        />,
        selector: 'activityCode',
        sortable: false,
        filtrable: true,
        className: "text-center",
        headerClassName: "react-table-header-class"
    },
    {
        name: <IntlMessages
            id="crm.client.designation"
        />,
        selector: 'name',
        sortable: false,
        filtrable: true,
        className: "text-center",
        headerClassName: "react-table-header-class"
    },
];