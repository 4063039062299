import React, {useEffect, useState} from "react";
import {AdaInputText} from "@adaming/ada-react-component";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../helpers/intlMessages";
import AdaSelectBuilder from "./ada.select.builder";
import * as service from "../../service/crud.service";
import {CRM_NAME, getHostByAppName} from "../../service/host";

export default function AdaAddressBuilder({register, errors, uri, relatedTo, inputName, setValue, defaultAddress, control, selectedObjects, dispatch}) {

    const [address, setAddress] = useState({});


    useEffect(() => {

        console.log("defaultAddress",defaultAddress)
        register({name: `${inputName}` + ".streetNumber"});
        register({name: `${inputName}` + ".streetName"});
        register({name: `${inputName}` + ".zipCode"});
        register({name: `${inputName}` + ".city"});
        register({name: `${inputName}` + ".district"});
        register({name: `${inputName}` + ".country"});

        if (relatedTo !== undefined) {
            register({name: `${inputName}` + ".id"});
            register({name: `${inputName}` + ".enable"});
            register({name: `${inputName}` + ".relatedTo"});

            service.getUri(uri).then (result => {
                let res = result.data[0]
                setValue(`${inputName}` + ".id", res.id);
                setValue(`${inputName}` + ".enable", res.enable);
                setValue(`${inputName}` + ".relatedTo", res.relatedTo);

                defaultAddress = {
                    id: res.id,
                    enable: res.enable,
                    relatedTo: res.relatedTo,
                    streetNumber: res.streetNumber,
                    streetName: res.streetName,
                    zipCode: res.zipCode,
                    codeCity: res.codeCity,
                    city: res.city,
                    district: res.district,
                    country: res.country
                }
                setAddress(defaultAddress);
            })
        }

    }, [relatedTo]);


    return (<div>
        <Row>
            <Col className="col-sm-1">
                <FormGroup>
                    <IntlMessages id="crm.client.contact.mailing.address.num"/>
                    <AdaInputText
                        id={`${inputName}` + ".streetNumberId"}
                        name={`${inputName}` + ".streetNumber"}
                        errors={errors}
                        defaultValue={address !== undefined ? address.streetNumber : ""}
                        innerRef={register}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <IntlMessages id="crm.client.contact.mailing.address"/>
                    <AdaInputText
                        id={`${inputName}` + ".streetNameId"}
                        name={`${inputName}` + ".streetName"}
                        errors={errors}
                        defaultValue={address !== undefined ? address.streetName : ""}
                        innerRef={register}
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup>
                    <IntlMessages id="crm.client.contact.mailing.address.city"/>
                    <AdaInputText
                        id={`${inputName}` + ".cityId"}
                        name={`${inputName}` + ".city"}
                        errors={errors}
                        defaultValue={address !== undefined ? address.city : ""}
                        innerRef={register}
                    />
                </FormGroup>
            </Col>
            <Col className="col-sm-2">
                <FormGroup>
                    <IntlMessages id="crm.client.contact.mailing.address.zipcode"/>
                    <AdaInputText
                        id={`${inputName}` + ".zipCodeId"}
                        name={`${inputName}` + ".zipCode"}
                        errors={errors}
                        defaultValue={address !== undefined ? address.zipCode : ""}
                        innerRef={register}
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup>
                    <IntlMessages id="crm.client.contact.mailing.address.district"/>
                    <AdaInputText
                        id={`${inputName}` + ".districtId"}
                        name={`${inputName}` + ".district"}
                        errors={errors}
                        defaultValue={address !== undefined ? address.district : ""}
                        innerRef={register}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <IntlMessages id="crm.client.contact.mailing.address.country"/>
                    <AdaInputText
                        id={`${inputName}` + ".countryId"}
                        name={`${inputName}` + ".country"}
                        errors={errors}
                        defaultValue={address !== undefined ? address.country : ""}
                        innerRef={register}
                    />
                </FormGroup>
            </Col>
        </Row>
    </div>);
}
