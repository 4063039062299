import clientsRoutes from "./clientsRoutes";
import contactsRoutes from "./contactsRoutes";
import activityAreasRoutes from "./activityAreaRoutes"

let crmRoutes = [];

if (Array.isArray(clientsRoutes)) {
    crmRoutes.push(...clientsRoutes);
}
if (Array.isArray(contactsRoutes)) {
    crmRoutes.push(...contactsRoutes);
}
if (Array.isArray(activityAreasRoutes)) {
    crmRoutes.push(...activityAreasRoutes);
}
export default crmRoutes;