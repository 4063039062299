import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const  columnsClient = [

    {
        name: <IntlMessages
            id="crm.client.designation"
        />,
        selector: "name",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="crm.client.description"
        />,
        selector: "description",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="crm.client.web"
        />,
        selector: "web",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="crm.common.status"
        />,
        selector: "actif",
        sortable: false,
        filtrable: false,
        format: row => (row.enable === true ? "Actif" : "Non Actif")
    },
    {
        name: <IntlMessages
            id="crm.client.owner"
        />,
        selector: "owner",
        sortable: false,
        filtrable: true,
    }
];
