import React, {useEffect} from "react";
import {
    CustomInput,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import AdaSelectBuilder from "../../views/commun/ada.select.builder";
import AdaAddressBuilder from "../../views/commun/ada.address.builder";
import {getHostByAppName, NOMENCLATURE_NAME} from "../../service/host";

const ContactForm = ({
                         listEntities,
                         listResponsible,
                         showContactModal,
                         close,
                         addNewItem,
                         editObject,
                         dispatch,
                         selectedObjects
                     }) => {
    const {
        register,
        errors,
        setValue,
        reset,
        handleSubmit,
        control
    } = useForm();

    useEffect(() => {
        register({name: "responsible"});
        register({name: "entitie"});
        register({name: "function"});
        register({name: "category"});
    }, []);

    useEffect(
        () => {
                console.log("editObject in form", editObject);
        },
        [editObject]
    );

    const handleChangeResponsible = selectedOption => {
        console.log("responsible", selectedOption);
        setValue("responsible", selectedOption.object);
    };

    const handleChangeEntity = selectedOption => {
        console.log("entitie", selectedOption);
        if (selectedOption !== null) {
            setValue("entitie", selectedOption.object);
        } else {
            setValue("entitie", undefined);
        }
    };

    const submit = data => {
        console.log("data in modal contact form ", data);
        addNewItem(data);
        reset();
    };

    return (
        <div>
            <Modal centered size="lg" isOpen={showContactModal} fade={false}>
                <ModalHeader>
                    <span>Contact</span>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col>
                                <Label/>

                                <FormGroup>
                                    <div>
                                        <Row>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <AdaSelectBuilder
                                                    errors={errors}
                                                    register={register}
                                                    setValue={setValue}
                                                    uri={`${getHostByAppName(NOMENCLATURE_NAME)}/civilitys`}
                                                    inputName={"civility"}
                                                    optionsType={"string"}
                                                    control={control}
                                                    dispatch={dispatch}
                                                />
                                            </Col>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "20px"}}
                                            >
                                                <FormGroup>
                                                    <CustomInput
                                                        type="switch"
                                                        id="actifContactClt1"
                                                        name="actif"
                                                        defaultChecked={editObject.actif !== undefined ? editObject.actif : true}
                                                        innerRef={register}
                                                        label={<IntlMessages id="crm.common.status"/>}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="crm.client.contact.responsible"/>
                                                    <AdaSelect
                                                        name="responsible"
                                                        errors={errors}
                                                        options={listResponsible}
                                                        defaultValue={{
                                                            key: null,
                                                            label: "Select...",
                                                            value: null,
                                                            object: null
                                                        }}
                                                        onChange={handleChangeResponsible}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.common.entities"/>
                                            <AdaSelect
                                                name="entitie"
                                                errors={errors}
                                                isClearable
                                                options={listEntities}
                                                onChange={handleChangeEntity}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.client.contact.firstname"/>
                                            <AdaInputText
                                                name="firstName"
                                                defaultValue={
                                                    editObject !== undefined ? editObject.firstName : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.client.contact.lastname"/>
                                            <AdaInputText
                                                name="lastName"
                                                defaultValue={
                                                    editObject !== undefined ? editObject.lastName : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.common.contacts.phone"/>
                                            <AdaInputText
                                                name="phone"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.phone
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.common.contacts.mobile"/>
                                            <AdaInputText
                                                name="mobile"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.mobile
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.common.contacts.email"/>
                                            <Input
                                                name="email"
                                                type="email"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.email
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.common.contacts.fax"/>
                                            <AdaInputText
                                                name="fax"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.fax
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.client.contact.function"/>
                                            <AdaSelectBuilder
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_functions`}
                                                inputName={"function"}
                                                optionsType={"string"}
                                                defaultId={selectedObjects.function}
                                                control={control}
                                                dispatch={dispatch}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="crm.client.contact.category"/>
                                            <AdaSelectBuilder
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_categorys`}
                                                inputName={"category"}
                                                optionsType={"string"}
                                                defaultId={selectedObjects.category}
                                                control={control}
                                                dispatch={dispatch}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <AdaAddressBuilder
                                    errors={errors}
                                    register={register}
                                    setValue={setValue}
                                    inputName={"address"}
                                    defaultAddress={editObject.address}
                                    control={control}
                                    dispatch={dispatch}
                                    selectedObjects={selectedObjects}
                                />

                                <FormGroup>
                                    <AdaButton
                                        className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                        type="submit"
                                    >
                                        <IntlMessages id="iam.common.add"/>
                                    </AdaButton>
                                    <AdaButton
                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                        onClick={() => close()}
                                    >
                                        <IntlMessages id="action.common.cancel"/>
                                    </AdaButton>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ContactForm;
