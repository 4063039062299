import React, {useEffect, useState} from "react";
import Gauge from "react-svg-gauge";
import UserInfoWrapper from "./userinfo.style";
import * as service from "../../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME} from "../../../../service/host";

const LatestActivity = ({icon, title, description, site, telephone, statut, owner, activityArea, email, fax, termsOfPayment, group}) => {

  const [actArea, setActArea] = useState({})

  useEffect(()=>{
    if(activityArea){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `company_activity_areas`,
          setActArea,
          error => console.log(error), activityArea
      );
    }
  },[activityArea])

  return (
    <UserInfoWrapper className="fill-height">
      <div className="card roe-shadow-2 fill-height">
        <div className="card-body">

          <div className="row">
            <div className="col-md-4 aligner-wrapper text-center">
              <img
               src={icon}
               style={{width:"140px"}}
              />
              <div style={{marginTop:"10px"}}>
                <h3 className="card-title fs-25 bold-text">{title}</h3>
              </div>
            </div>
            <div className="col-md-4 legend-wrapper">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-18 bold-text" >{description}</h4>
                </div>
                <small className="text-muted ml-3">Description</small>
              </div>
              <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-info mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-18 bold-text">{telephone}</h4>
                </div>
                <small className="text-muted ml-3">Téléphone</small>
              </div>
              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                  <a
                      href={`https://${site}/`}
                      rel="noopener noreferrer"
                      target="_blank">
                    {site}
                  </a>
                </div>
                <small className="text-muted ml-3">Site web</small>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-success mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-18 bold-text" >{termsOfPayment} jour(s)</h4>
                </div>
                <small className="text-muted ml-3">Echéance</small>
              </div>
            </div>

            <div className="col-md-4 legend-wrapper">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-18 bold-text">{email}</h4>
                </div>
                <small className="text-muted ml-3">Email</small>
              </div>
              <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-info mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-18 bold-text">{actArea && actArea.name ? actArea.name : "ND"}
                  </h4>
                </div>
                <small className="text-muted ml-3">Secteur d'activité</small>
              </div>
              <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-info mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-18 bold-text">{statut}
                  </h4>
                </div>
                <small className="text-muted ml-3">Statut</small>
              </div>
             
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-success mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-18 bold-text">{group}</h4>
                </div>
                <small className="text-muted ml-3">Groupe</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserInfoWrapper>
  );
};

export default LatestActivity;
