import React, {useEffect, useState} from "react";
import {Col, FormGroup, Modal, ModalBody, ModalHeader, Row, Toast, ToastBody, ToastHeader} from "reactstrap";
import WrapperInformation from "../adaClientUpdate/wrapper";
import {contacticon} from "../../../../helpers/constants";
import * as service from "../../../../service/crud.service";
import {CRM_NAME, getHostByAppName} from "../../../../service/host";
import {AdaButton} from "@adaming/ada-react-component";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router-dom";

export default function ContactsDetails({client, edit=false, editContact}) {
    const [contacts, setContacts] = useState([]);
    const [showContactModal, setShowContactModal] = useState(false);
    let history = useHistory();

    useEffect(() => {
        if(client.id){
            service.get(
                getHostByAppName(CRM_NAME),
                `clients/contacts/${client.id}`,
                callbackContactsList,
                (error)=> console.log("error",error)
            );
        }
    }, [client]);

    const callbackContactsList = (data) =>{
        console.log("Map contacts", data);
        setContacts(data);
    }

    const actionClick = (id) =>{
        if(edit)
            editContact(id)
    }

    return(
        <div>
            <Row>
                {contacts !== undefined &&
                (contacts.length > 0 &&
                    contacts.map((contactObj, key1) => (
                        <Col key={key1}>
                            <Row>
                                <Col className="mb-2">
                                    <div className="roe-card-header">
                                        <span className="hash"># </span>{contactObj.name}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {contactObj.listContacts.map((contact,key) =>
                                    (
                                        <Col key={key}
                                             className="col-sm-4"
                                        >
                                            < WrapperInformation title={contact.firstName + " " + contact.lastName}
                                                                 icon={contacticon}
                                                                 informationLigne1={contact.contactInformation.email}
                                                                 informationLigne2={contact.contactInformation.mobile}
                                                                 onclickDetails={()=>editContact(contact.id)}
                                                                 showFooter={edit}/>
                                        </Col>
                                    )
                                )
                                }

                            </Row>
                            <br/>
                        </Col>
                    )))}
            </Row>
            <div>
                <Row>
                    <Col className="col-sm-2 ml-auto">

                            <AdaButton
                                className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                onClick={() =>  history.push(`/contacts/new`)}
                            >
                                <IntlMessages id="iam.common.add.contact"/>
                            </AdaButton>

                    </Col>
                </Row>
            </div>
        </div>
    )

}
