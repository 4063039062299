import React, {useEffect, useState} from "react";
import { Col, CustomInput, Row, FormGroup } from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import { AdaSelectBuilder } from "@adaming/ada-react-business-component";
import { getHostByAppName, NOMENCLATURE_NAME } from "../../../../service/host";
import {Controller} from "react-hook-form";
import * as service from "../../../../service/crud.service";
import UserInfoWrapper from "./userinfo.style";

export default function UpdateInformation({icon, title,
  client,
  errors,
  register,
  setValue,
  control,
                                              dispatch
}) {

    const [actList, setActList] = useState([]);

    useEffect(()=>{
        if(client.id){
            console.log("in update information", client)
            service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `company_activity_areas`, callback)
            dispatch({"activityArea" : client.activityArea ? client.activityArea : undefined})
        }
    },[])

    const callback = data =>{
        setActList(data)
        let obj = data.filter(obj => obj.key === client.activityArea)[0];
        console.log("obj", obj, data, client.activityArea)
        setValue("act", obj)
    }
    const handleChangeNationality = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("activityArea", selectedOption);
        dispatch({"activityArea" : selectedOption.value})
    };

  return (
    <div>


        <UserInfoWrapper className="fill-height">
            <div className="card roe-shadow-2 fill-height">
                <div className="card-body">

                    <div className="row">
                        <div className="col-md-4 aligner-wrapper text-center">
                            <img
                                src={icon}
                                style={{width:"140px"}}
                            />
                            <div style={{marginTop:"10px"}}>
                                <h3 className="card-title fs-25 bold-text">{title}</h3>
                            </div>
                        </div>
                        <div className="col-md-4 legend-wrapper">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="description"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.description}
                                    />
                                </div>
                                <small className="text-muted ml-3">Description</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="contactInformation.phone"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.contactInformation.phone}
                                    />
                                </div>
                                <small className="text-muted ml-3">Téléphone</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="web"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.web}
                                    />
                                </div>
                                <small className="text-muted ml-3">Site web</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="termsOfPayment"
                                        type="number"
                                        defaultValue={client.termsOfPayment}
                                        step="1"
                                        errors={errors}
                                        innerRef={register}
                                    />
                                </div>
                                <small className="text-muted ml-3">Echéance</small>
                            </div>
                        </div>

                        <div className="col-md-4 legend-wrapper">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="contactInformation.email"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.contactInformation.email}
                                    />
                                </div>
                                <small className="text-muted ml-3">Email</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <Controller
                                        as={<AdaSelect name={"act"} />}
                                        name={"act"}
                                        isClearable
                                        errors={errors}
                                        options={actList}
                                        onChange={handleChangeNationality}
                                        control={control}
                                        innerRef={register}
                                    />
                                </div>
                                <small className="text-muted ml-3">Secteur d'activité</small>
                            </div>
                            
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="group"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.group}
                                    />
                                </div>
                                <small className="text-muted ml-3">groupe</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <CustomInput
                                        type="switch"
                                        id="statusInfClt"
                                        name="enable"
                                        defaultChecked={client.enable}
                                        innerRef={register}
                                        label={<IntlMessages id="crm.client.enable" />}
                                    />
                                </div>
                                <small className="text-muted ml-3">Statut</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserInfoWrapper>
    </div>
  );
}
