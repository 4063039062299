import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const columnsContact = [

    {
        name: <IntlMessages
            id="crm.client.contact.lastname.full"
        />,
        selector: "firstName",
        sortable: false,
        filtrable: true,
        cell : row => <h2>{row.firstName} {row.lastName}</h2>
    },
    {
        name: <IntlMessages
            id="crm.common.contacts.phone"
        />,
        selector: "contactInformation.phone",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="crm.common.contacts.mobile"
        />,
        selector: "contactInformation.mobile",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="crm.common.contacts.email"
        />,
        selector: "contactInformation.email",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="crm.common.status"
        />,
        selector: "enable",
        sortable: false,
        filtrable: true,

        cell : row =>  <div>
            {row.enable && <i className="fas fa-check" style={{fontSize: 15}}/>}
            {!row.enable && <i className="fas fa-ban" style={{fontSize: 15}}/>}

        </div>
    }
];
