import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row, CustomInput, Table, Input} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import {buildingEnterprise, infoFisc} from "../../../../helpers/constants";

export default function ClientEntities(props) {
    const {register, handleSubmit, errors, setValue, reset} = useForm();
    const [errorMessage, setErrorMessage] = useState("");
    const [listEntities, setListEntities] = useState([]);
    const [idEntity, setIdEntity] = useState(0);

    const onSubmit = data => {
        setErrorMessage("")
        setIdEntity(prevIdEntity => prevIdEntity + 1);
        if(listEntities.map((ent => ent.name)).filter(name => name === data.name).length>0){
            setErrorMessage("valeur existante")
            return ;
        }
        let entityObj = {
            idEntity: idEntity,
            name: data.name,
            enable: data.enable,
            owner: "admin"
        };
        let tempEntitiesList = listEntities.concat(entityObj);
        setListEntities(tempEntitiesList);
        props.initEntityList(entityObj);
        props.updateAction({entities: tempEntitiesList});
        setValue("name", "")
    };

    const nextStep = () => {
        props.initEntityList(
            listEntities.map(object => ({
                key: object.idEntity,
                label: object.name,
                value: object.name,
                object: object
            }))
        );
        props.updateAction({entities: listEntities});
        props.step("5");
    };

    const previousStep = () =>{
        props.updateAction({entities: listEntities});
        props.step("3");
    }

    useEffect(() => {
        },
        [listEntities]
    );

    useEffect(() => {
        console.log("first step")
        if(props.clientObj != undefined && props.clientObj.entities !== undefined && props.clientObj.entities.length > 0){
            setListEntities(props.clientObj.entities);
        }
        register({name: "name"}, {required: "Ce champ est obligatoire."});
    }, []);

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }
        });
        return arr;
    };

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={buildingEnterprise}
                                         style={{width:"120px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Entités</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>

                                            <FormGroup>
                                                <div>
                                                    <Row>
                                                        <Col
                                                            className="col-sm-2"
                                                            style={{"margin-top": "20px"}}
                                                        >
                                                            <FormGroup>
                                                                <CustomInput
                                                                    id="enableEntityId"
                                                                    type="switch"
                                                                    name="enable"
                                                                    defaultChecked={true}
                                                                    innerRef={register}
                                                                    label={<IntlMessages id="crm.common.status"/>}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup>
                                                                <IntlMessages id="crm.client.designation"/>
                                                                <AdaInputText
                                                                    name="name"
                                                                    errors={errors}
                                                                    innerRef={register}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup>
                                                                <AdaButton
                                                                    type="submit"
                                                                    style={{marginTop: 22}}
                                                                    className="no-border c-outline-AdaSubmit c-btn-sm c-btn-wide ml-sm-2"
                                                                >
                                                                    <IntlMessages id="crm.common.create.entity"/>
                                                                </AdaButton>{" "}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                {listEntities.length > 0 && (
                                                    <Table striped>
                                                        <thead>
                                                        <tr>
                                                            <th/>
                                                            <th/>
                                                            <th/>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {listEntities.map((entity, key) => (
                                                            <tr>
                                                                <th scope="row"/>
                                                                <td>
                                                                    <AdaButton
                                                                        className="no-border c-AdaNext c-btn-sm c-btn-wide"
                                                                        onClick={() =>
                                                                            setListEntities(
                                                                                deleteElementFromList(
                                                                                    key,
                                                                                    listEntities
                                                                                )
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fas fa-trash"/>
                                                                    </AdaButton>
                                                                </td>
                                                                <td>{entity.name}</td>
                                                                <td>
                                                                    {entity.enable === true
                                                                        ? "Actif"
                                                                        : "Non Actif"}{" "}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </FormGroup>

                                            <FormGroup>
                                                <AdaButton
                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                                    onClick={nextStep}
                                                >
                                                    <IntlMessages id="action.common.next.contact"/>
                                                </AdaButton>
                                                <AdaButton onClick={previousStep}
                                                           className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.common.previous.information.fiscal"/>
                                                </AdaButton>
                                                <AdaButton
                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    onClick={props.cancelAction}
                                                >
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
